import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Category } from '../../models/category/category';
import { ApiCommonService } from '../common/api-common.service';
import { environment } from '@lesaidants/env';
import { APICallReturn } from '../APICallReturn';

/**
 *@ignore
 */
@Injectable({
    providedIn: 'root',
})
/**
 * Class category api service used for communication with back-end
 */
export class CategoryApiService extends ApiCommonService<Category> {
    /**
     * URL to ping
     */
    private baseUrl = `${environment.url}/${environment.version}/category`;
    protected resourceUrl = `${environment.url}/${environment.version}/category`;

    /**
     * Method returning an Category created from json data
     */
    fromJson(json: Category): Category {
        return Category.fromJson(json);
    }
    /**
     * Method returning an Category in a json format
     */
    toJson(model: Category): Category {
        return Category.toJson(model);
    }
    /**
     * Method returning all Categorys in Observable Category Array
     */
    getCategories(token: string): Observable<APICallReturn | Category[] | undefined> {
        this.resourceUrl = `${this.baseUrl}/list`;
        return super.getAll(token);
    }
    /**
     * Method returning Observable Category get by id
     */
    getCategory(token: string, id: string): Observable<APICallReturn | Category | undefined> {
        this.resourceUrl = `${this.baseUrl}/info`;
        return super.getById(token, id);
    }
    /**
     * Method returning Observable Category updated by token
     */
    updateCategory(token: string, id: string, category: Category): Observable<APICallReturn | Category | undefined> {
        this.resourceUrl = `${this.baseUrl}/update/${id}`;
        return super.update(token, category);
    }
    /**
     * Method returning Observable User create by auth token
     */
    createCategory(token: string, category: Category): Observable<APICallReturn | Category | undefined> {
        this.resourceUrl = `${this.baseUrl}/add`;
        return super.create(category, token);
    }
    /**
     * Method returning to delete an ehpad
     */
    deleteCategory(token: string, id: string): Observable<APICallReturn | Category | undefined> {
        this.resourceUrl = `${this.baseUrl}/delete/${id}`;
        return super.delete(token);
    }
}
