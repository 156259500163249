import { FormBuilder, FormGroup } from "@angular/forms";
import { Thread } from "../../models/thread/thread";
export interface mapObject {
    title: string,
    uuid: string
}

export class ThreadForm {
    get(arg0: string): import("@angular/forms").FormArray<any> {
      throw new Error('Method not implemented.');
    }
    public threadForm: FormGroup;
    
    constructor() {
        let fb: FormBuilder = new FormBuilder();
        this.threadForm = fb.group({
            // parentUuid: [undefined],
            // index: [undefined],
            uuid: [undefined],
            title: [undefined],
            message: [undefined],
            previous: [undefined],
            threads: [undefined],
            // threads: [undefined],
        }
        );
    }

    get form(): FormGroup {
        return this.threadForm
    }

    markAsDirty(): void {
        Object.keys(this.form.controls).forEach((key) => {
            const control = this.form.get(key);

            if (control instanceof FormGroup) {
                this.markAsDirty();
            } else {
                if (control && !control.valid) {
                    control.markAsDirty();
                }
            }
        });
    }

    resetForm(): void {
        this.threadForm.reset();
    }

    convertMapIntobject(map?: Map<string, string>): mapObject[] {
        if (map === undefined) return []

        let obj: mapObject[] = []
        map.forEach((value: string, key: string) => obj.push({title: key, uuid: value}));

        return obj;
    }

        


    fromThread(thread: Thread): void {
        if (thread) {
            this.threadForm.patchValue({
                // index: thread.index || undefined,
                title: thread.title || undefined,
                message: thread.message || undefined,
                previous: thread.previous || undefined,
                uuid: thread.uuid || undefined,
                threads: thread.threads || undefined,
            })
        }
    }

    toThread(): Thread {
        const formValue = this.threadForm.value;
        return new Thread(
            formValue.title,
            formValue.message,
            formValue.previous,
        )
    }

}
