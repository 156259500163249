import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Newsletter } from '../../models/newsletter/newsletter';
import { ApiCommonService } from '../common/api-common.service';
import { environment } from '@lesaidants/env';
import { APICallReturn } from '../APICallReturn';

/**
 *@ignore
 */
@Injectable({
    providedIn: 'root',
})
/**
 * Class newsletter api service used for communication with back-end
 */
export class NewsletterApiService extends ApiCommonService<Newsletter> {
    /**
     * URL to ping
     */
    private baseUrl = `${environment.url}/${environment.version}/newsletter`;
    protected resourceUrl = `${environment.url}/${environment.version}/newsletter`;

    /**
     * Method returning an Newsletter created from json data
     */
    fromJson(json: Newsletter): Newsletter {
        return Newsletter.fromJson(json);
    }
    /**
     * Method returning an Newsletter in a json format
     */
    toJson(model: Newsletter): Newsletter {
        return Newsletter.toJson(model);
    }
    /**
     * Method returning all Newsletters in Observable Newsletter Array
     */
    getNewsletters(token: string): Observable<APICallReturn | Newsletter[] | undefined> {
        this.resourceUrl = `${this.baseUrl}/list`;
        return super.getAll(token);
    }
    /**
     * Method returning Observable Newsletter get by id
     */
    getNewsletter(token: string, id: string): Observable<APICallReturn | Newsletter | undefined> {
        this.resourceUrl = `${this.baseUrl}/info`;
        return super.getById(token, id);
    }
    /**
     * Method returning Observable Newsletter updated by token
     */
    updateNewsletter(
        token: string,
        newsletter: Newsletter,
        id: string,
    ): Observable<APICallReturn | Newsletter | undefined> {
        this.resourceUrl = `${this.baseUrl}/update/${id}`;
        return super.update(token, newsletter);
    }
    /**
     * Method returning Observable User create by auth token
     */
    createNewsletter(token: string, newsletter: Newsletter): Observable<APICallReturn | Newsletter | undefined> {
        this.resourceUrl = `${this.baseUrl}/add`;
        return super.create(newsletter, token);
    }
    /**
     * Method returning to delete an ehpad
     */
    deleteNewsletter(token: string, id: string): Observable<APICallReturn | Newsletter | undefined> {
        this.resourceUrl = `${this.baseUrl}/delete/${id}`;
        return super.delete(token);
    }
}
