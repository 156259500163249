import { Component, Input } from '@angular/core';
import { EhpadForm } from '@lesaidantsbackoffice/core/forms';
import { Cities } from './utils/cities/cities';

@Component({
    selector: 'app-ehpad-information-editor',
    templateUrl: './ehpad-information-editor.component.html',
    styleUrls: ['./ehpad-information-editor.component.scss'],
})
export class EhpadInformationEditorComponent {
    @Input() ehpadForm!: EhpadForm;
    @Input() isLoading = false;
    @Input() cancel!: () => void;
    @Input() onSubmit!: () => void;
    cities = Cities;

    onFileSelected(event: Event, field: string): void {
        const eventTarget = event.target as HTMLInputElement;

        if (eventTarget.files && eventTarget.files.length > 0) {
            const file = eventTarget.files[0];
            const reader = new FileReader();

            if (field === 'imageUrl') {
                reader.onload = (e: ProgressEvent<FileReader>) => {
                    const base64String = e.target?.result as string;
                    this.ehpadForm?.form.patchValue({ imageUrl: base64String });
                };
            } else {
                reader.onload = (e: ProgressEvent<FileReader>) => {
                    const base64String = e.target?.result as string;
                    this.ehpadForm?.form.patchValue({ logoUrl: base64String });
                };
            }

            reader.readAsDataURL(file);
        }
    }
}
