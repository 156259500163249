export class UserPro {
    constructor(
        public job?: string,
        public goal?: string,
        public description?: string,
        public status?: string,
        public updatedAt?: Date,
        public id?: number,
        public uuid?: string,
        public userId?: number,
    ) {}

    static updateFromJson(toFill: Partial<UserPro>, filler: Partial<UserPro>): UserPro {
        return Object.assign(toFill, filler) as UserPro;
    }

    static fromJson(json: Partial<UserPro>): UserPro {
        return new UserPro(
            json.job ?? undefined,
            json.goal ?? undefined,
            json.description ?? undefined,
            json.status ?? undefined,
            json.updatedAt ?? undefined,
            json.id ?? undefined,
            json.uuid ?? undefined,
            json.userId ?? undefined,
        );
    }

    static toJson(userPro: UserPro): Partial<UserPro> {
        return {
            job: userPro.job ?? undefined,
            goal: userPro.goal ?? undefined,
            description: userPro.description ?? undefined,
            status: userPro.status ?? undefined,
        };
    }
}
