import { Component, OnInit } from '@angular/core';
import { TagForm } from '@lesaidantsbackoffice/core/forms';
import { Tag } from '@lesaidantsbackoffice/core/models';
import { TagService } from '@lesaidantsbackoffice/core/services/services';
import { MessageService } from 'primeng/api';
import { catchError, finalize, map, of } from 'rxjs';
import { APICallReturn } from 'src/app/core/services/APICallReturn';

@Component({
    selector: 'app-tag-list',
    templateUrl: './tag-list.component.html',
    styleUrls: ['./tag-list.component.scss'],
})
export class TagListComponent implements OnInit {
    search: string = '';
    tagForm: TagForm = new TagForm();
    isNew: boolean = false;
    isOpen: boolean = false;
    isLoading = false;
    tags: Tag[] = [];
    selectedTagId: string | undefined;

    constructor(
        private tagService: TagService,
        private messageService: MessageService,
    ) {}

    ngOnInit(): void {
        this.fetchTags();
    }

    create(): void {
        this.isOpen = true;
        this.isNew = true;
    }

    edit(tag: Tag) {
        this.selectedTagId = tag.uuid;
        this.tagForm.fromTag(tag);
        this.isOpen = true;
        this.isNew = false;
    }

    delete(id: string): void {
        this.tagService
            .deleteTag(id)
            .pipe(
                map(() => {
                    this.messageService.add({
                        severity: 'success',
                        detail: 'Catégorie supprimée',
                    });
                    this.fetchTags();
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    refreshList() {}

    cancel(): void {
        this.tagForm.resetForm();
        this.isOpen = false;
    }

    onSubmit(): void {
        this.isLoading = true;

        if (this.isNew) {
            this.tagService
                .createTag(this.tagForm.toTag())
                .pipe(
                    map((tag: APICallReturn | Tag | undefined) => {
                        if (tag instanceof Tag) {
                            this.messageService.add({
                                severity: 'success',
                                detail: 'Tag créé',
                            });
                            this.fetchTags();
                            this.isOpen = false;
                        }
                    }),
                    finalize(() => {
                        this.tagForm.resetForm();
                        this.isLoading = false;
                    }),
                    catchError(() => {
                        this.isLoading = false;
                        return of(null);
                    }),
                )
                .subscribe();
        } else {
            this.tagService
                .updateTag(this.selectedTagId || '', this.tagForm.toTag())
                .pipe(
                    map((tag: APICallReturn | Tag | undefined) => {
                        if (tag instanceof Tag) {
                            this.messageService.add({
                                severity: 'success',
                                detail: 'Tag mis à jour',
                            });
                            this.fetchTags();
                            this.isOpen = false;
                        }
                    }),
                    finalize(() => {
                        this.tagForm.resetForm();
                        this.isLoading = false;
                    }),
                    catchError(() => {
                        this.isLoading = false;
                        return of(null);
                    }),
                )
                .subscribe();
        }
    }

    private fetchTags(): void {
        this.isLoading = true;
        this.tagService
            .getTags()
            .pipe(
                map((tags: APICallReturn | Tag[] | undefined) => {
                    if (Array.isArray(tags) && tags.length > 0 && tags[0] instanceof Tag) {
                        this.tags = tags;
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }
}
