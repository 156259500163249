import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewsletterForm } from '@lesaidantsbackoffice/core/forms';
import { CategoryService, NewsletterService, TagService } from '@lesaidantsbackoffice/core/services/services';
import { AuthService } from 'src/app/core/auth';
import { Category, Newsletter, Tag } from '@lesaidantsbackoffice/core/models';
import { catchError, finalize, map, of } from 'rxjs';
import { MessageService } from 'primeng/api';
import { APICallReturn } from 'src/app/core/services/APICallReturn';

@Component({
    selector: 'app-newsletter-editor',
    templateUrl: './newsletter-editor.component.html',
    styleUrls: ['./newsletter-editor.component.scss'],
})
export class NewsletterEditorComponent {
    isPreview: boolean = false;
    newsletterForm: NewsletterForm = new NewsletterForm();
    newsletter: Newsletter = new Newsletter();
    categories: Category[] = [];
    tags: Tag[] = [];
    id?: string;
    isNew: boolean = false;
    isLoading: boolean = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        public newsletterService: NewsletterService,
        private categoryService: CategoryService,
        private tagService: TagService,
        private authService: AuthService,
        private messageService: MessageService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe((params) => {
            this.id = params.get('id') || 'new';
            this.isNew = this.id === 'new';
        });

        if (!this.isNew) {
            this.isLoading = true;
            this.newsletterService
                .getNewsletter(this.id || '')
                .pipe(
                    map((newsletter: APICallReturn | Newsletter | undefined) => {
                        if (newsletter instanceof Newsletter) {
                            this.newsletter = newsletter;
                            this.newsletterForm.fromNewsletter(newsletter);
                        }
                    }),
                    finalize(() => (this.isLoading = false)),
                    catchError(() => {
                        this.isLoading = false;
                        this.router.navigate([`404`]);
                        return of(null);
                    }),
                )
                .subscribe();
        }
        this.fetchTags();
        this.fetchCategories();
    }

    openPreview(): void {
        this.isPreview = true;
    }

    closePreview(): void {
        this.isPreview = false;
    }

    create(): void {
        this.isLoading = true;
        this.newsletterService
            .createNewsletter(this.authService.getToken(), this.newsletterForm.toNewsletter())
            .pipe(
                map((newsletter: APICallReturn | Newsletter | undefined) => {
                    if (newsletter instanceof Newsletter) {
                        this.router.navigate([`newsletter/${newsletter.uuid}`]);
                        this.messageService.add({
                            severity: 'success',
                            detail: 'Newsletter créé',
                        });
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.messageService.add({
                        severity: 'error',
                        detail: 'Erreur lors de la création du newsletter',
                    });
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    edit(): void {
        this.isLoading = true;
        this.newsletterService
            .updateNewsletter(this.authService.getToken(), this.newsletterForm.toNewsletter(), this.id || '')
            .pipe(
                map((newsletter: APICallReturn | Newsletter | undefined) => {
                    if (newsletter instanceof Newsletter) {
                        this.newsletter = newsletter;
                        this.newsletterForm.fromNewsletter(newsletter);
                        this.messageService.add({
                            severity: 'success',
                            detail: 'Newsletter mis à jour',
                        });
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.messageService.add({
                        severity: 'error',
                        detail: 'Erreur lors de la mise à jour du newsletter',
                    });
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    onSubmit(): void {
        if (this.newsletterForm?.form.valid && this.isNew) {
            this.create();
        } else if (this.newsletterForm?.form.valid) {
            this.edit();
        } else {
            this.newsletterForm?.markAsDirty();
        }
    }

    private fetchTags(): void {
        this.isLoading = true;
        this.tagService
            .getTags()
            .pipe(
                map((tags: APICallReturn | Tag[] | undefined) => {
                    if (Array.isArray(tags) && tags.length > 0 && tags[0] instanceof Tag) {
                        this.tags = tags;
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    private fetchCategories(): void {
        this.isLoading = true;
        this.categoryService
            .getCategories()
            .pipe(
                map((categories: APICallReturn | Category[] | undefined) => {
                    if (Array.isArray(categories) && categories.length > 0 && categories[0] instanceof Category) {
                        this.categories = categories;
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }
}
