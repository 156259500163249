import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Ehpad } from '@lesaidantsbackoffice/core/models';
import { CategoryService, EhpadService, TagService } from '@lesaidantsbackoffice/core/services/services';
import { MessageService } from 'primeng/api';
import { catchError, finalize, map, of } from 'rxjs';
import { AuthService } from 'src/app/core/auth';
import { APICallReturn } from 'src/app/core/services/APICallReturn';

@Component({
    selector: 'app-ehpad-list',
    templateUrl: './ehpad-list.component.html',
    styleUrls: ['./ehpad-list.component.scss'],
})
export class EhpadListComponent implements OnInit {
    ehpads: Ehpad[] = [];
    search: string = '';
    isLoading = false;

    constructor(
        private router: Router,
        public ehpadService: EhpadService,
        public categoryService: CategoryService,
        public tagService: TagService,
        private messageService: MessageService,
    ) {}

    ngOnInit(): void {
        this.isLoading = true;
        this.fetchEhpads();
    }

    create(): void {
        this.router.navigate(['/ehpad/new']);
    }

    edit(id: number): void {
        this.router.navigate([`ehpad/${id}`]);
    }

    delete(id: string): void {
        this.ehpadService
            .deleteEhpad(id)
            .pipe(
                map(() => {
                    this.messageService.add({
                        severity: 'success',
                        detail: 'Établissement supprimé',
                    });
                    this.fetchEhpads();
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    checkAvailability(availabilityPerMonth: Record<string, number>): boolean {
        if (!availabilityPerMonth || Object.keys(availabilityPerMonth).length === 0) {
            return false;
        }
        for (const key in availabilityPerMonth) {
            if (availabilityPerMonth[key] === undefined || availabilityPerMonth[key] === 0) {
                return false;
            }
        }
        return true;
    }

    private fetchEhpads(): void {
        this.ehpadService
            .getEhpads()
            .pipe(
                map((ehpads: APICallReturn | Ehpad[] | undefined) => {
                    if (Array.isArray(ehpads) && ehpads.length > 0 && ehpads[0] instanceof Ehpad) {
                        this.ehpads = ehpads;
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }
}
