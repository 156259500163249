<div class="w-full px-8 relative text-color-aidant-dark">
    <div class="flex flex-nowrap">
        <div *ngIf="this.userProForm" class="flex flex-col justify-center w-1/2">
            <div class="w-9/12">
                <form [formGroup]="this.userProForm.form">
                    <span class="p-float-label flex mb-10 items-center">
                        <input pInputText id="job" class="w-full" type="text" formControlName="job" />
                        <label for="job">Profession</label>
                    </span>
                    <span class="p-float-label flex mb-10 items-center">
                        <input pInputText id="goal" class="w-full" type="text" formControlName="goal" />
                        <label for="goal">Objectif du partenariat</label>
                    </span>
                    <div class="w-full mb-10">
                        <p class="font-medium text-color-aidant-dark pl-4 text-left">Détaillez votre demande :</p>
                        <textarea class="resize-none w-full" rows="7" cols="30" formControlName="description" pInputTextarea ></textarea>
                    </div>
                    <span class="p-float-label w-full mb-10">
                        <p-dropdown styleClass="w-full text-left" inputId="status" [options]="this.userProForm.status" formControlName="status" optionLabel="label" optionValue="value"></p-dropdown>
                        <label for="status">Status</label>
                    </span>
                    <div class="mb-2 flex justify-center items-center space-x-3">
                        <button pButton label="Refuser" class="p-button-raised p-button-secondary w-36" (click)="onDelete()"></button>
                        <button pButton label="Valider" class="p-button-raised w-36" (click)="onSubmit()"></button>
                    </div>
                </form>
            </div>
        </div>
        <!-- && this.userPro.status === 'confirmed' -->
        <div *ngIf="this.userProForm" class="flex flex-col w-1/2">
            <div class="w-9/12">
                <!-- <form [formGroup]="this.userProForm.form"> -->
                    <span class="p-float-label w-full mb-10">
                        <p-multiSelect styleClass="w-full" inputId="zoneList" [options]="[]" formControlName="zoneList" optionLabel="name" optionValue="code" display="chip" [showClear]="true"></p-multiSelect>
                        <label for="zoneList">Permissions</label>
                    </span>
                    <div class="mb-2 flex justify-center items-center space-x-3">
                        <button pButton label="Valider" class="p-button-raised w-36" (click)="onSubmit()"></button>
                    </div>
                <!-- </form> -->
            </div>
        </div>
    </div>
</div>
<p-toast></p-toast>