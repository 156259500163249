import { Component } from '@angular/core';

@Component({
    selector: 'app-meets',
    templateUrl: './meets.component.html',
    styleUrls: ['./meets.component.scss'],
})
export class MeetsComponent {
    constructor() {}
}
