import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Ehpad } from '../../models/ehpad/ehpad';
import { Month, Monthes } from '../../objects/monthes/monthes-objects';
export class EhpadForm {
    public ehpadForm: FormGroup;

    constructor() {
        let fb: FormBuilder = new FormBuilder();
        const actualMonth: Month = Monthes[new Date().getMonth()];

        this.ehpadForm = fb.group({
            name: [undefined, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
            contactMail: [undefined, [Validators.required, Validators.email]],
            phoneNumber: [undefined, [Validators.required, Validators.pattern(/^\d{10}$/)]],
            address: [undefined, [Validators.required, Validators.maxLength(100)]],
            city: [undefined, [Validators.required, Validators.maxLength(50)]],
            zipCode: [undefined, [Validators.required, Validators.pattern(/^\d{5}$/)]],
            imageUrl: [undefined, Validators.required],
            logoUrl: [undefined, Validators.required],
            availabilityPerMonth: fb.group({}),
            zoneList: [undefined],
            coordinates: fb.group({
                longitude: [undefined],
                latitude: [undefined],
            }),
        });
    }

    get form(): FormGroup {
        return this.ehpadForm;
    }

    markAsDirty(): void {
        Object.keys(this.form.controls).forEach((key) => {
            const control = this.form.get(key);

            if (control instanceof FormGroup) {
                this.markAsDirty();
            } else {
                if (control && !control.valid) {
                    control.markAsDirty();
                }
            }
        });
    }

    resetForm(): void {
        this.ehpadForm.reset();
    }

    fromEhpad(ehpad: Ehpad): void {
        if (ehpad) {
            this.ehpadForm.patchValue({
                name: ehpad.name || undefined,
                contactMail: ehpad.contactMail || undefined,
                phoneNumber: ehpad.phoneNumber || undefined,
                address: ehpad.address || undefined,
                city: ehpad.city || undefined,
                zipCode: ehpad.zipCode || undefined,
                imageUrl: ehpad.imageUrl || undefined,
                logoUrl: ehpad.logoUrl || undefined,
                availabilityPerMonth: ehpad.availabilityPerMonth || undefined,
                zoneList: ehpad.zoneList || undefined,
                coordinates: ehpad.coordinates || undefined,
            });
            if (ehpad.availabilityPerMonth) {
                const availabilityGroup = this.ehpadForm.get('availabilityPerMonth') as FormGroup;

                Object.keys(ehpad.availabilityPerMonth).forEach((month) => {
                    if (!availabilityGroup.get(month)) {
                        availabilityGroup.addControl(
                            month,
                            new FormControl(ehpad.availabilityPerMonth ? ehpad.availabilityPerMonth[month] : undefined),
                        );
                    } else {
                        availabilityGroup
                            ?.get(month)
                            ?.setValue(ehpad.availabilityPerMonth ? ehpad.availabilityPerMonth[month] : undefined);
                    }
                });
            }
        }
    }

    toEhpad(): Ehpad {
        const formValue = this.ehpadForm.value;
        return new Ehpad(
            formValue.name,
            formValue.contactMail,
            formValue.phoneNumber,
            formValue.address,
            formValue.city,
            formValue.zipCode,
            formValue.imageUrl,
            formValue.logoUrl,
            formValue.availabilityPerMonth,
            formValue.zoneList,
            formValue.coordinates,
        );
    }
}
