<div class="w-full flex justify-center pb-10">
    <div class="card">
        <p-table
            #tagsTable
            [value]="tags"
            [paginator]="true"
            dataKey="id"
            [rows]="10"
            [showCurrentPageReport]="true"
            [loading]="isLoading"
            [tableStyle]="{ 'min-width': '70rem' }"
            currentPageReportTemplate="Total {totalRecords}"
            [globalFilterFields]="['name']"
            [rowsPerPageOptions]="[5, 10, 20]"
            [selectionMode]="'single'"
        >
            <ng-template pTemplate="caption">
                <div class="flex-start">
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" [(ngModel)]="search" (input)="tagsTable.filterGlobal(search, 'contains')" placeholder="Rechercher un tag" class="w-72" />
                    </span>
                    <p-button class="right-5 absolute" (onClick)="create()">Créer</p-button>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 30%;">Nom</th>
                    <th style="width: 5%;">Couleur</th>
                    <th style="width: fill-available;" pSortableColumn="updatedAt" class="flex justify-content-center align-items-center">
                        Date de modification
                        <p-sortIcon field="updatedAt"></p-sortIcon>
                    </th>
                    <th style="width: 5%;"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-tag>
                <tr class="cursor-pointer hover:bg-[#184E77]" (click)="edit(tag)">
                    <td>{{ tag.name }}</td>
                    <td class="text-center ">
                        <div class="rounded-full w-8 h-8" [style.background]="tag.color"></div>
                    </td>
                    <td>{{ tag.updatedAt | date }}</td>
                    <td (click)="$event.stopPropagation(); delete(tag.uuid)">
                        <div class="p-4 items-center flex justify-center rounded-full cursor-pointer hover:bg-red-500 hover:text-white">
                            <i class="pi pi-trash cursor-pointer"></i>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<app-tag-editor *ngIf="isOpen" [isNew]="isNew" [isLoading]="isLoading" [tagForm]="tagForm" [cancel]="cancel.bind(this)" [onSubmit]="onSubmit.bind(this)"></app-tag-editor>
<p-toast position="bottom-center" [breakpoints]="{'1920px': {width: '350px'}}"></p-toast>
