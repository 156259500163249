import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Newsletter } from '@lesaidantsbackoffice/core/models';
import { CategoryService, NewsletterService, TagService } from '@lesaidantsbackoffice/core/services/services';
import { MessageService } from 'primeng/api';
import { catchError, finalize, map, of } from 'rxjs';
import { APICallReturn } from 'src/app/core/services/APICallReturn';

@Component({
    selector: 'app-newsletter-list',
    templateUrl: './newsletter-list.component.html',
    styleUrls: ['./newsletter-list.component.scss'],
})
export class NewsletterListComponent implements OnInit {
    newsletters: Newsletter[] = [];
    search: string = '';
    isLoading = false;

    constructor(
        private router: Router,
        public newsletterService: NewsletterService,
        public categoryService: CategoryService,
        public tagService: TagService,
        private messageService: MessageService,
    ) {}

    ngOnInit(): void {
        this.isLoading = true;
        this.fetchNewsletters();
    }

    create(): void {
        this.router.navigate(['/newsletter/new']);
    }

    edit(id: string): void {
        this.router.navigate([`newsletter/${id}`]);
    }

    delete(id: string): void {
        this.newsletterService
            .deleteNewsletter(id)
            .pipe(
                map(() => {
                    this.messageService.add({
                        severity: 'success',
                        detail: 'Établissement supprimé',
                    });
                    this.fetchNewsletters();
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    private fetchNewsletters(): void {
        this.newsletterService
            .getNewsletters()
            .pipe(
                map((newsletters: APICallReturn | Newsletter[] | undefined) => {
                    if (Array.isArray(newsletters) && newsletters.length > 0 && newsletters[0] instanceof Newsletter) {
                        this.newsletters = newsletters;
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }
}
