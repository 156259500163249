import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserPro } from '../../models/user-pro/user-pro';

export class UserProForm {
    userProForm: FormGroup;
    jobs: string[] = ['Médecin généraliste', 'Infirmier', 'Aide-soignant', 'Pharmacien', 'Kinésithérapeute'];
    goals: string[] = ['Publicité Les-Aidants', 'Rédaction de guide'];
    status = [
        { label: 'A analyser', value: null },
        { label: 'En attente de confirmation utilisateur', value: 'pending' },
        { label: 'Utilsiateur confirmé', value: 'confirmation' },
        { label: 'Validé', value: 'validated' },
    ];

    constructor() {
        let fb: FormBuilder = new FormBuilder();

        this.userProForm = fb.group({
            job: [undefined, [Validators.required]],
            goal: [undefined, [Validators.required]],
            description: [undefined, [Validators.required, Validators.minLength(2)]],
            status: [undefined],
        });
    }

    get form(): FormGroup {
        return this.userProForm;
    }

    markAsDirty(): void {
        Object.keys(this.form.controls).forEach((key) => {
            const control = this.form.get(key);

            if (control instanceof FormGroup) {
                this.markAsDirty();
            } else {
                if (control && !control.valid) {
                    control.markAsDirty();
                }
            }
        });
    }

    resetForm(): void {
        this.userProForm.reset();
    }

    fromUserPro(userPro: UserPro): void {
        if (userPro) {
            this.userProForm.patchValue({
                job: userPro.job || undefined,
                goal: userPro.goal || undefined,
                description: userPro.description || undefined,
                status: userPro.status || undefined,
            });
        }
    }

    toUserPro(): UserPro {
        const formValue = this.userProForm.value;

        return new UserPro(formValue.job, formValue.goal, formValue.description, formValue.status);
    }
}
