<div class="w-full flex justify-center pb-10">
    <div class="card">
        <p-table #categoriesTable
                 [value]="categories"
                 [paginator]="true"
                 dataKey="id"
                 [rows]="10"
                 [showCurrentPageReport]="true"
                 [loading]="isLoading"
                 [tableStyle]="{ 'min-width': '70rem' }"
                 currentPageReportTemplate="Total {totalRecords}"
                 [globalFilterFields]="['name']"
                 [rowsPerPageOptions]="[5, 10, 20]"
                 [selectionMode]="'single'">
            <ng-template pTemplate="caption">
                <div class="flex-start">
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" [(ngModel)]="search" (input)="categoriesTable.filterGlobal(search, 'contains')" placeholder="Rechercher une catégorie" class="w-72" />
                    </span>
                    <p-button class="right-5 absolute" (onClick)="create()">Créer</p-button>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 30%;">
                        <div class="flex justify-content-center align-items-center">
                            Nom
                            <p-columnFilter type="text" field="name" display="menu" [showMatchModes]="true" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                        </div>
                    </th>
                    <th style="width: 5%;">Couleur</th>
                    <th style="width: fill-available;" pSortableColumn="updatedAt" class="flex justify-content-center align-items-center">
                        Date de modification
                        <p-sortIcon field="updatedAt"></p-sortIcon>
                    </th>
                    <th style="width: 5%;"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-category>
                <tr class="cursor-pointer hover:bg-[#184E77]" (click)="edit(category)">
                    <td>{{ category.name }}</td>
                    <td class="text-center">
                        <div class="rounded-full w-8 h-8" [style.background]="category.color"></div>
                    </td>
                    <td>{{ category.updatedAt | date:'medium' }}</td>
                    <td (click)="$event.stopPropagation(); delete(category.uuid)">
                        <div class="p-4 items-center flex justify-center rounded-full cursor-pointer hover:bg-red-500 hover:text-white">
                            <i class="pi pi-trash cursor-pointer"></i>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<app-category-editor *ngIf="isOpen" [isNew]="isNew" [isLoading]="isLoading" [categoryForm]="categoryForm" [cancel]="cancel.bind(this)" [onSubmit]="onSubmit.bind(this)"></app-category-editor>
<p-toast position="bottom-center" [breakpoints]="{'1920px': {width: '350px'}}"></p-toast>
