import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APICallReturn } from '../APICallReturn';
import { Invite } from '../../models/invite/invite';
import { ApiCommonService } from '../common/api-common.service';
import { environment } from '@lesaidants/env';

@Injectable({
    providedIn: 'root',
})
export class InviteApiService extends ApiCommonService<Invite> {
    private baseUrl = `${environment.url}/${environment.version}/meet/member`;
    protected resourceUrl = `${environment.url}/${environment.version}/meet/member`;

    fromJson(json: Invite): Invite {
        return Invite.fromJson(json);
    }

    toJson(model: Invite): Invite {
        return Invite.toJson(model);
    }

    /**
     * Method returning all Invites in Observable Invite Array
     */
    getInvites(token: string): Observable<APICallReturn | Invite[] | undefined> {
        this.resourceUrl = `${this.baseUrl}/list`;
        return super.getAll(token);
    }

    /**
     * Method returning Observable User create by auth token
     */
    createInvite(token: string, invite: Invite, userID?: String): Observable<APICallReturn | Invite | undefined> {
        if (userID) {
            this.resourceUrl = `${this.baseUrl}/invite/${userID}`;
        } else {
            this.resourceUrl = `${this.baseUrl}/invite`;
        }
        return super.create(invite, token);
    }

    addUser(token: string, invite: Invite, userID: String): Observable<APICallReturn | Invite | undefined> {
        this.resourceUrl = `${this.baseUrl}/add/${userID}g`;
        return super.create(invite, token);
    }

    /**
     * Method returning to delete an Invite
     */
    deleteInvite(token: string, id: string): Observable<APICallReturn | Invite | undefined> {
        this.resourceUrl = `${this.baseUrl}/revoke/${id}`;
        return super.delete(token);
    }
}
