import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Ehpad } from '../../models/ehpad/ehpad';
import { ApiCommonService } from '../common/api-common.service';
import { environment } from '@lesaidants/env';
import { APICallReturn } from '../APICallReturn';

/**
 *@ignore
 */
@Injectable({
    providedIn: 'root',
})
/**
 * Class ehpad api service used for communication with back-end
 */
export class EhpadApiService extends ApiCommonService<Ehpad> {
    /**
     * URL to ping
     */
    private baseUrl = `${environment.url}/${environment.version}/ehpad`;
    protected resourceUrl = `${environment.url}/${environment.version}/ehpad`;

    /**
     * Method returning an Ehpad created from json data
     */
    fromJson(json: Ehpad): Ehpad {
        return Ehpad.fromJson(json);
    }
    /**
     * Method returning an Ehpad in a json format
     */
    toJson(model: Ehpad): Ehpad {
        return Ehpad.toJson(model);
    }
    /**
     * Method returning all Ehpads in Observable Ehpad Array
     */
    getEhpads(token: string): Observable<APICallReturn | Ehpad[] | undefined> {
        this.resourceUrl = `${this.baseUrl}/list`;
        return super.getAll(token);
    }
    /**
     * Method returning Observable Ehpad get by id
     */
    getEhpad(token: string, id: string): Observable<APICallReturn | Ehpad | undefined> {
        this.resourceUrl = `${this.baseUrl}/info`;
        return super.getById(token, id);
    }
    /**
     * Method returning Observable Ehpad updated by token
     */
    updateEhpad(token: string, ehpad: Ehpad, id: string): Observable<APICallReturn | Ehpad | undefined> {
        this.resourceUrl = `${this.baseUrl}/update/${id}`;
        return super.update(token, ehpad);
    }
    /**
     * Method returning Observable User create by auth token
     */
    createEhpad(token: string, ehpad: Ehpad): Observable<APICallReturn | Ehpad | undefined> {
        this.resourceUrl = `${this.baseUrl}/add`;
        return super.create(ehpad, token);
    }
    /**
     * Method returning to delete an ehpad
     */
    deleteEhpad(token: string, id: string): Observable<APICallReturn | Ehpad | undefined> {
        this.resourceUrl = `${this.baseUrl}/delete/${id}`;
        return super.delete(token);
    }
}
