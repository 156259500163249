import { Component } from '@angular/core';

@Component({
    selector: 'app-dictionnary',
    templateUrl: './dictionnary.component.html',
    styleUrls: ['./dictionnary.component.scss'],
})
export class DictionnaryComponent {
    selectedPage: 'categories' | 'tags' = 'categories';

    constructor() {}

    setSelectedPage(str: 'categories' | 'tags') {
        this.selectedPage = str;
    }
}
