/**
 * Class with the data for an userPermission
 */
export class UserPermission {
    /**
     *@ignore
     */
    constructor(public ehpads?: string[]) {}

    /**
     * Method returning an UserPermission created from json data
     */
    static fromJson(json: Partial<UserPermission>): UserPermission {
        return new UserPermission(json.ehpads ?? undefined);
    }

    /**
     * Method returning UserPermission in a json format
     */
    static toJson(userPermission: UserPermission): UserPermission {
        return {
            ehpads: userPermission.ehpads ?? undefined,
        };
    }
}
