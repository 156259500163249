<div class="w-full flex justify-center pb-10">
    <div class="card">
        <p-table
            #threadsTable
            [value]="threads"
            [paginator]="true"
            dataKey="id"
            [rows]="10"
            [showCurrentPageReport]="true"
            [loading]="isLoading"
            [tableStyle]="{ 'min-width': '60rem' }"
            currentPageReportTemplate="Total {totalRecords}"
            [globalFilterFields]="['name']"
            [rowsPerPageOptions]="[5, 10, 20]"
        >
            <ng-template pTemplate="caption">
                <div class="flex-start
                ">
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text"  (input)="threadsTable.filterGlobal(search, 'contains')" placeholder="Rechercher un thread..." class="w-72" />
                    </span>
                    <p-button class="right-5 absolute" (onClick)="create()">Créer</p-button>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Nom (titre du thread principal)</th>
                    <!-- <th>Taille (Nombre d'itération)</th> -->
                    <!-- <th>Concepteur</th> -->
                    <!-- <th>Date de Modification</th> -->
                    <th>Edit</th>
                    <th>Delete</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-thread>
                <tr class="cursor-pointer" (click)="edit(thread.uuid)">
                    <td>{{ thread.title }}</td>
                    <!-- <td>{{ this.maxIterations }}</td> -->
                    <!-- <td> {{ thread.createdBy.firstName }} {{ thread.createdBy.lastName }}</td>
                    <td>{{ thread.updatedAt | date:'dd/MM/yyyy HH:mm:ss' }}</td> -->
                    <td (click)="edit(thread.uuid)" class="cursor-pointer hover:bg-[#184E77] hover:text-white rounded-full">
                        <i class="pi pi-pencil cursor-pointer"></i>
                    </td>
                    <td (click)="delete(thread.uuid)" class="cursor-pointer hover:bg-red-500 hover:text-white rounded-full">
                        <i class="pi pi-trash cursor-pointer"></i>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

</div>

