import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { APICallReturn } from '../APICallReturn';
import { AuthService } from '../../auth';
import { EhpadApiService } from './ehpad-api-service';
import { Ehpad } from '../../models/ehpad/ehpad';

@Injectable({
    providedIn: 'root',
})
export class EhpadService {
    constructor(
        private ehpadApiService: EhpadApiService,
        private authService: AuthService,
    ) {}

    /* CALL APIS */

    public createEhpad(ehpad: Ehpad): Observable<APICallReturn | Ehpad | undefined> {
        if (ehpad === undefined) {
            return of(undefined);
        }
        return this.ehpadApiService.createEhpad(this.authService.getToken(), ehpad).pipe();
    }

    public updateEhpad(id: string, ehpad: Partial<Ehpad>): Observable<APICallReturn | Ehpad | undefined> {
        if (ehpad === undefined) {
            return of(undefined);
        }
        return this.ehpadApiService.updateEhpad(this.authService.getToken(), ehpad, id).pipe(
            map((data: APICallReturn | Ehpad | undefined) => {
                return data;
            }),
        );
    }

    public getEhpad(id: string): Observable<APICallReturn | Ehpad | undefined> {
        return this.ehpadApiService.getEhpad(this.authService.getToken(), id);
    }

    public getEhpads(): Observable<APICallReturn | Ehpad[] | undefined> {
        return this.ehpadApiService.getEhpads(this.authService.getToken());
    }

    public deleteEhpad(id: string): Observable<APICallReturn | Ehpad | undefined> {
        return this.ehpadApiService.deleteEhpad(this.authService.getToken(), id);
    }
}
