import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../service/AuthService';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { UserService } from '@lesaidantsbackoffice/core/services/services';

export const AuthGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    const authService = inject(AuthService);
    const router = inject(Router);
    const userService = inject(UserService);

    if (!authService.isAuthenticated()) {
        return router.createUrlTree(['/login']);
    }

    const user = userService.get();
    console.log('HERE DUDE', user);

    if (!user?.admin) {
        const allowedPaths = ['ehpads', 'ehpad'];
        const requestedPath = route.routeConfig?.path?.split('/')[0];

        if (!allowedPaths.includes(requestedPath || '')) {
            return router.createUrlTree(['/ehpads']);
        }
    }

    return true;
};
