import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Guide } from '../../models/guide/guide';
import { Tag } from '../../models/tag/tag';

export class GuideForm {
    public guideForm: FormGroup;

    constructor() {
        let fb: FormBuilder = new FormBuilder();

        this.guideForm = fb.group({
            name: [undefined, [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
            status: [undefined, Validators.required],
            category: [undefined],
            tags: [undefined],
            content: [undefined],
            url: [undefined, Validators.required],
        });
    }

    get form(): FormGroup {
        return this.guideForm;
    }

    markAsDirty(): void {
        Object.keys(this.form.controls).forEach((key) => {
            const control = this.form.get(key);

            if (control instanceof FormGroup) {
                this.markAsDirty();
            } else {
                if (control && !control.valid) {
                    control.markAsDirty();
                }
            }
        });
    }

    resetForm(): void {
        this.guideForm.reset();
    }

    fromGuide(guide: Guide): void {
        if (guide) {
            this.guideForm.patchValue({
                name: guide.name || undefined,
                status: guide.status || undefined,
                category: guide.category?.uuid || undefined,
                tags: guide.tags?.map((tag: Tag) => tag.uuid) || undefined,
                content: guide.content || undefined,
                url: guide.url || undefined,
            });
        }
    }

    toGuide(): Guide {
        const formValue = this.guideForm.value;
        return new Guide(
            formValue.name,
            formValue.status,
            formValue.category,
            formValue.tags,
            formValue.content,
            formValue.url,
        );
    }
}
