import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { canvasObject, canvasNode } from '../canvas-object';

@Component({
  selector: 'app-canva-thread',
  templateUrl: './canva-thread.component.html',
  styleUrls: ['./canva-thread.component.scss']
})
export class CanvaThreadComponent implements AfterViewInit {
  @ViewChild('canvasElement') canvasElement!: ElementRef<HTMLCanvasElement>
  
  private ctx!: CanvasRenderingContext2D
  private isDragging: boolean = false
  private rectSize = {x: 300, y: 500}

  public CanvaSize = {x: 5000, y: 5000}
  public mousePosition = {x: 0, y: 0}

  private canvasTree: canvasNode =  {
    actualNode: { color: '#FF0000', text: 'Root Node' },
    children: [
        {
            actualNode: { color: '#00FF00', text: 'Child 1' },
            children: [
                {
                    actualNode: { color: '#0000FF', text: 'Child 1.1' },
                    children: [
                        {
                            actualNode: { color: '#FFFF00', text: 'Child 1.1.1' },
                            children: [
                                {
                                    actualNode: { color: '#FF00FF', text: 'Child 1.1.1.1' },
                                    children: []
                                }
                            ]
                        }
                    ]
                },
                {
                    actualNode: { color: '#FF9900', text: 'Child 1.2' },
                    children: [
                        {
                            actualNode: { color: '#99FF00', text: 'Child 1.2.1' },
                            children: [
                                {
                                    actualNode: { color: '#00FFFF', text: 'Child 1.2.1.1' },
                                    children: []
                                },
                                {
                                  actualNode: { color: '#00FFFF', text: 'Child 1.2.1.2' },
                                  children: []
                              }
                            ]
                        }
                    ]
                },
                
            ]
        },
        {
            actualNode: { color: '#FF0099', text: 'Child 2' },
            children: [
                {
                    actualNode: { color: '#9900FF', text: 'Child 2.1' },
                    children: [
                        {
                            actualNode: { color: '#00FF99', text: 'Child 2.1.1' },
                            children: [
                                {
                                    actualNode: { color: '#FF66FF', text: 'Child 2.1.1.1' },
                                    children: []
                                },
                                {
                                  actualNode: { color: '#FF66FF', text: 'Child 2.1.1.2' },
                                  children: []
                              }
                            ]
                        }
                    ]
                }
            ]
        },
        {
          actualNode: { color: '#FF0099', text: 'Child 3' },
          children: [
              {
                  actualNode: { color: '#9900FF', text: 'Child 3.1' },
                  children: [
                      {
                          actualNode: { color: '#00FF99', text: 'Child 3.1.1' },
                          children: [
                              {
                                  actualNode: { color: '#FF66FF', text: 'Child 3.1.1.1' },
                                  children: []
                              },
                              {
                                actualNode: { color: '#FF66FF', text: 'Child 3.1.1.2' },
                                children: []
                            }
                          ]
                      }
                  ]
              }
          ]
      }
    ]
};

  ngAfterViewInit(): void {
    this.ctx = this.canvasElement.nativeElement.getContext("2d")!

    this.draw()
  }

  private drawRectangle(obj: canvasObject, ChildWidth: number = 250, childHeight: number = 500) {
    this.ctx.fillStyle = obj.color;
    this.ctx.fillRect(obj.x! - this.mousePosition.x, obj.y! - this.mousePosition.y, ChildWidth, childHeight);

    this.ctx.fillStyle = '#000';
    this.ctx.font = '70px Arial';
    this.ctx.textAlign = 'left';
    this.ctx.textBaseline = 'middle';
    this.ctx.fillText(obj.text, (obj.x! + ChildWidth / 2) - this.mousePosition.x, (obj.y! + childHeight / 2) - this.mousePosition.y);
    
  }

  private draw() {
    const canvasElement = this.canvasElement.nativeElement;
    this.ctx.clearRect(0, 0, canvasElement.width, canvasElement.height);

    let nb: number = -1;
    let maxNumber: number = -1;

    this.getXPosition(this.canvasTree, undefined)
    for (let i: number = 25; nb != 0; i += 450) {
      nb = this.getNumbersOfChildForAGivenX(this.canvasTree, i)
      if (nb > maxNumber)
        maxNumber = nb
    }
    this.getYPosition(this.canvasTree,this.canvasTree, 0, maxNumber * 1500, undefined)
  }

  private getYPosChildOdd(index: number, middle: number, gapBetweenEachChild: number, childSize: number): number {
    if (index === middle) return 0

    let SquaredNbDistance: number = index - middle

    let distance: number = 250 + (Math.abs(SquaredNbDistance) * gapBetweenEachChild) + (Math.abs(SquaredNbDistance) * childSize)
    return SquaredNbDistance < 0 ? distance * (-1) : distance

  }

  private getYPosChilddEven(index: number, middle: number, gapBetweenEachChild: number, childSize: number): number {
    let calcul: number = index > middle ? middle + 0.5 : middle - 0.5
    return ((index - middle) * gapBetweenEachChild) + (Math.abs(calcul - index) * childSize)
  }

  private getYPosOfChild(childrenNumber: number, index: number, gapBetweenEachChild: number = 1000, childSize: number = 500): number {

    let isOdd: boolean = childrenNumber % 2 === 1
    let middle: number = (childrenNumber / 2) + 0.5;

    
    return isOdd ? this.getYPosChildOdd(index, middle, gapBetweenEachChild, childSize) : this.getYPosChilddEven(index, middle, gapBetweenEachChild, childSize)
  }

  private getNumbersOfChildForAGivenX(actualNode: canvasNode, xSearched: number, actualNb: number = 0): number {

    if (actualNode.actualNode.x === xSearched)
      actualNb++;
        
    actualNode.children.forEach((child: canvasNode) => {
      actualNb = this.getNumbersOfChildForAGivenX(child, xSearched, actualNb)
    })

    return actualNb
  }

  private getYPositionFirstDepth(overAllSize: number, index: number): number {
    return 1;
  }

  private getYPosition(actualNode: canvasNode, rootNode: canvasNode, index: number, overAllSize: number, previousNode?: canvasNode): void {

    if (!previousNode) 
      actualNode.actualNode.y = this.CanvaSize.y / 2 - 250
    else if (previousNode && rootNode.actualNode.y && previousNode.actualNode.x) {
      let nbOfTotalNextNext: number = this.getNumbersOfChildForAGivenX(rootNode, previousNode.actualNode.x + (450 * 2))
      let nbOfTotalNext: number = this.getNumbersOfChildForAGivenX(rootNode, previousNode.actualNode.x + (450))
      actualNode.actualNode.y = 1 
    }

    actualNode.children.forEach((node: canvasNode, index: number) => {
      // this.getYPosition(node, rootNode, index, actualNode)
    })

    this.drawRectangle(actualNode.actualNode)
  }

  private getXPosition(actualNode: canvasNode, previousNode?: canvasNode): void {

    if (!previousNode) 
      actualNode.actualNode.x = 25
    else if (previousNode && previousNode.actualNode.x)
      actualNode.actualNode.x = previousNode.actualNode.x + 450

    actualNode.children.forEach((node: canvasNode) => {
      this.getXPosition(node, actualNode)
    })
  }


  onMouseMove(event: MouseEvent) {
    if (!this.isDragging) return;
 
    this.mousePosition.x -= event.movementX
    this.mousePosition.y -= event.movementY * 2
    this.draw()
  }


  onMouseClick(isDown: boolean) {
    if (isDown) {
      this.isDragging = true
      return;
    }
    this.isDragging = false;
  }
}
