import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MeetForm, TagForm } from '@lesaidantsbackoffice/core/forms';
import { CategoryService, MeetService, UserService } from '@lesaidantsbackoffice/core/services/services';
import { AuthService } from 'src/app/core/auth';
import { APICallReturn } from 'src/app/core/services/APICallReturn';
import { Category, Meet, Tag, User } from '@lesaidantsbackoffice/core/models';
import { catchError, finalize, map, of } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TagService } from '@lesaidantsbackoffice/core/services/services';

@Component({
    selector: 'app-meet-editor',
    templateUrl: './meet-editor.component.html',
    styleUrls: ['./meet-editor.component.scss'],
})
export class MeetEditorComponent {
    inviteList: User[] = [];
    public meetForm: MeetForm = new MeetForm();
    categories: Category[] = [];
    tags: Tag[] = [];
    id?: string;
    isNew: boolean = false;
    isLoading: boolean = false;
    show: boolean = true;
    urlMeet: any;
    title: string[] = ['Créer un événement', 'Modifier un événement'];
    selectedUsers: any[] = [];

    onFileSelected(event: Event): void {
        const eventTarget = event.target as HTMLInputElement;

        if (eventTarget.files && eventTarget.files.length > 0) {
            const file = eventTarget.files[0];
            const reader = new FileReader();

            reader.onload = (e: ProgressEvent<FileReader>) => {
                const base64String = e.target?.result as string;
                this.meetForm?.form.patchValue({ image: base64String });
            };

            reader.readAsDataURL(file);
        }
    }

    constructor(
        private activatedRoute: ActivatedRoute,
        public meetService: MeetService,
        private authService: AuthService,
        private categoryService: CategoryService,
        private tagService: TagService,
        private userInviteService: UserService,
        private messageService: MessageService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe((params) => {
            this.id = params.get('id') || 'new';
            this.isNew = this.id === 'new';
        });

        this.isLoading = true;
        this.userInviteService
            .getUsers()
            .pipe(
                map((users: APICallReturn | User[] | undefined) => {
                    if (Array.isArray(users) && users.length > 0 && users[0] instanceof User) {
                        this.inviteList = users;
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();

        if (!this.isNew) {
            this.isLoading = true;
            this.meetService
                .getMeet(this.authService.getToken(), this.id || '')
                .pipe(
                    map((meet: APICallReturn | Meet | undefined) => {
                        if (meet instanceof Meet) {
                            this.meetForm.fromMeet(meet);
                        }
                    }),
                    finalize(() => (this.isLoading = false)),
                    catchError(() => {
                        this.isLoading = false;
                        this.router.navigate([`404`]);
                        return of(null);
                    }),
                )
                .subscribe();
        }
        this.fetchTags();
        this.fetchCategories();
    }

    onPickUser(): void {
        console.log('PICK USER');
        console.log(this.selectedUsers);
        console.log(this.meetForm.form.get('maxUsers')?.value);
        // if (this.meetForm.form.get('maxUsers')?.value && this.selectedUsers.length > this.meetForm.form.get('maxUsers')?.value) {

        // }
    }

    private fetchTags(): void {
        this.isLoading = true;
        this.tagService
            .getTags()
            .pipe(
                map((tags: APICallReturn | Tag[] | undefined) => {
                    if (Array.isArray(tags) && tags.length > 0 && tags[0] instanceof Tag) {
                        this.tags = tags;
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    private fetchCategories(): void {
        this.isLoading = true;
        this.categoryService
            .getCategories()
            .pipe(
                map((categories: APICallReturn | Category[] | undefined) => {
                    if (Array.isArray(categories) && categories.length > 0 && categories[0] instanceof Category) {
                        this.categories = categories;
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    create(): void {
        this.isLoading = true;
        this.meetService
            .createMeet(this.authService.getToken(), this.meetForm.toMeet())
            .pipe(
                map((meet: APICallReturn | Meet | undefined) => {
                    if (meet instanceof Meet) {
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Succès',
                            detail: 'Le meet a bien été créé',
                        });
                        this.router.navigate([`/meet/${meet.uuid}`]);
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Erreur',
                        detail: 'Une erreur est survenue',
                    });
                    return of(null);
                }),
            )
            .subscribe();
    }

    edit(): void {
        this.isLoading = true;
        this.meetService
            .updateMeet(this.authService.getToken(), this.meetForm.toMeet(), this.id || '')
            .pipe(
                map((meet: APICallReturn | Meet | undefined) => {
                    if (meet instanceof Meet) {
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Succès',
                            detail: 'Le meet a été modifié',
                        });
                        this.router.navigate([`/meet/${meet.uuid}`]);
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Erreur',
                        detail: 'Une erreur est survenue',
                    });
                    return of(null);
                }),
            )
            .subscribe();
    }

    onSubmit(): void {
        if (this.meetForm?.form.valid && this.isNew) {
            this.create();
        } else if (this.meetForm?.form.valid) {
            this.edit();
        } else {
            this.meetForm?.markAsDirty();
        }
    }

    delete(): void {
        this.isLoading = true;
        this.meetService
            .deleteMeet(this.id || '')
            .pipe(
                map(() => {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Succès',
                        detail: 'Le meet a bien été supprimé',
                    });
                    this.router.navigate(['/meets']);
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Erreur',
                        detail: 'Une erreur est survenue',
                    });
                    return of(null);
                }),
            )
            .subscribe();
    }

    onUpload(event: any): void {
        console.log(event);
    }
}
