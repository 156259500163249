import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserForm } from '@lesaidantsbackoffice/core/forms';
import { User } from '@lesaidantsbackoffice/core/models';
import { CategoryService, UserService, TagService } from '@lesaidantsbackoffice/core/services/services';
import { MessageService } from 'primeng/api';
import { catchError, finalize, map, of } from 'rxjs';
import { AuthService } from 'src/app/core/auth';
import { APICallReturn } from 'src/app/core/services/APICallReturn';
@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
    public userForm: UserForm = new UserForm();
    public users: User[] = [];
    public filteredUsers: User[] = [];
    public search: string = '';
    public isLoading = false;
    public allColumns: string[] = ['isValidated', 'status', 'isProfessional'];
    public actualColumn: string = 'isValidated';
    public isValidated: boolean = undefined!;
    public isProfessional: boolean = undefined!;
    public status: string[] = [];
    public UsedFilters: number = 0;

    constructor(
        private authService: AuthService,
        public userService: UserService,
        public categoryService: CategoryService,
        public tagService: TagService,
        private router: Router,
        private messageService: MessageService,
    ) {}

    ngOnInit(): void {
        this.isLoading = true;
        this.fetchUsers();
    }

    public create(): void {
        this.router.navigate(['user/new']);
    }

    public edit(id: number): void {
        this.router.navigate([`user/${id}`]);
    }

    public cancel() {}

    public delete(id: number): void {
        this.userService
            .deleteUser(id.toString())
            .pipe(
                map(() => {
                    this.messageService.add({
                        severity: 'success',
                        detail: 'Établissement supprimé',
                    });
                    this.fetchUsers();
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    private fetchUsers(): void {
        this.userService
            .getUsers()
            .pipe(
                map((users: APICallReturn | User[] | undefined) => {
                    if (Array.isArray(users) && users.length > 0 && users[0] instanceof User) {
                        this.users = users;
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }
}
