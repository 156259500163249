import { Component } from '@angular/core';

@Component({
    selector: 'app-newsletters',
    templateUrl: './newsletters.component.html',
    styleUrls: ['./newsletters.component.scss'],
})
export class NewslettersComponent {
    constructor() {}
}
