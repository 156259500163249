import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Thread } from '@lesaidantsbackoffice/core/models';
import { ThreadService } from '@lesaidantsbackoffice/core/services/services';
import { AuthService } from 'src/app/core/auth';
import { MenuItem, MenuItemCommandEvent } from 'primeng/api';
import { child } from 'src/app/core/models/thread/thread';

@Component({
  selector: 'app-general-thread',
  templateUrl: './general-thread.component.html',
  styleUrls: ['./general-thread.component.scss']
})
export class GeneralThreadComponent implements OnInit {

  public thread!: Thread

  public actualID!: string | null
  public menuItem: MenuItem[] = []
  public answers: child[] | undefined = undefined
  public RootName: string | undefined
  public isCreating: boolean = false
  public isCanvaSide: boolean = false

  public isCallApi: boolean = false;

  constructor(private threadService: ThreadService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    
    this.activatedRoute.paramMap.subscribe((param: ParamMap) => {
      this.actualID = param.get('id')
    })

    if (!this.actualID) {
      this.router.navigate(['/404'])
      return
    }

    if (this.actualID !== "new")
      this.FillBreadCrumb(this.actualID, true, true)
    else
      this.isCreating = true;
  }

  public FillBreadCrumb(uuid: string, isAdding: boolean = true, isRoot: boolean = false): void {
    if (this.isCallApi)
      return
    this.isCallApi = true

    this.threadService.getThread(this.authService.getToken(), uuid).subscribe((data) => {
      if (data instanceof Thread) {
        if (isAdding)
          this.menuItem = [...this.menuItem, {label: data.title, command: this.changeNavigation.bind(this), badge: this.menuItem.length.toString(), id:data.uuid}]
        if (isRoot)
          this.RootName = data.title
        this.thread = data
        this.answers = data.threads
        this.isCallApi = false
      }
    })
  }

  public changeNavigation(event: MenuItemCommandEvent) {
    let items: MenuItem[] = [...this.menuItem]

    this.menuItem = items.splice(0, Number(event.item?.badge) + 1)
    this.FillBreadCrumb(this.menuItem[this.menuItem.length - 1].id!, false)
  }

  public setCreating(bool: boolean) {
    this.isCreating = bool
  }

}
