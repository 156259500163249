import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserForm } from '@lesaidantsbackoffice/core/forms';
import { UserService } from '@lesaidantsbackoffice/core/services/services';
import { MessageService } from 'primeng/api';
import { catchError, finalize, of, tap } from 'rxjs';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [MessageService],
})
/**
 * Component to manage the Login page
 */
export class LoginComponent {
    /**
     * Form for login page
     */
    userForm: UserForm = new UserForm();
    isLoading: boolean = false;
    /**
     *@ignore
     */
    constructor(
        private userService: UserService,
        private router: Router,
        private messageService: MessageService,
    ) {}

    /**
     * Submit the connection form to the back-end by a POST query when the user click on the "Valider" button
     */
    onSubmit() {
        const emailControl = this.userForm.form.get('email');
        const passwordControl = this.userForm.form.get('password');

        if (emailControl?.valid && passwordControl?.valid) {
            this.isLoading = true;
            this.userService
                .loginUser(this.userForm.toUser())
                .pipe(
                    tap(() => this.router.navigate([''])),
                    finalize(() => (this.isLoading = false)),
                    catchError(() => {
                        this.userForm.form.patchValue({ password: undefined });
                        this.messageService.add({
                            severity: 'error',
                            detail: 'Email ou mot de passe invalide',
                        });
                        this.isLoading = false;
                        return of(null);
                    }),
                )
                .subscribe();
        } else {
            emailControl!.markAsDirty();
            passwordControl!.markAsDirty();
            this.userForm.form.patchValue({ password: undefined });
            this.messageService.add({
                severity: 'error',
                detail: 'Email ou mot de passe invalide',
            });
        }
    }
}
