import { Meet } from '../meet/meet';

export class Invite {
    constructor(  
        public id?: number,
        public uuid?: string,
        public mainUserId?: string,
        public meet?: Meet,
        public createdAt?: Date,
        public updatedAt?: Date
    ) {}

    static fromJson(json: Partial<Invite>): Invite {
        return new Invite(
            json.id ?? undefined,
            json.uuid ?? undefined,
            json.mainUserId ?? undefined,
            json.meet ? Meet.fromJson(json.meet) : undefined,
            json.createdAt ?? undefined,
            json.updatedAt ?? undefined,
        );
    }

    static toJson(invite: Invite): Invite {
        return {
            id: invite.id ?? undefined,
            uuid: invite.uuid ?? undefined,
            mainUserId: invite.mainUserId ?? undefined,
            meet: invite.meet ? Meet.toJson(invite.meet) : undefined,
        };
    }
}
