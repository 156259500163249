
<footer class="bg-white">
    <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div class="md:flex md:justify-between">
            <div class="grid grid-flow-col auto-cols-max">
                <div>
                    <h2 class="mb-6 text-sm font-semibold text-color-aidant-dark uppercase">Contact</h2>
                    <div class="flex flex-col items-start">
                        <a class="p-2 text-neutral-68 text-sm font-medium transition-transform transform hover:text-color-aidant-dark hover:scale-105 hover-link mb-2" href="tel:+33783105353">
                            <i class="pi pi-whatsapp" style="margin-right: 5px;"></i>
                            +33 (0) 7 83 10 53 53
                        </a>
                        <a class="p-2 text-neutral-68 text-sm font-medium transition-transform transform hover:text-color-aidant-dark hover:scale-105 hover-link" href="mailto:contact@les-aidants-france.fr">
                            <i class="pi pi-envelope" style="margin-right: 5px;"></i>
                            {{"contact@les-aidants-france.fr"}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <hr class="my-6 border-gray-200 sm:mx-auto lg:my-8" />
        <div class="flex items-center justify-center">
            <div class="flex space-x-6 md:space-x-8">
                <a href="" target="_blank" class="flex items-center group transition-all duration-300 ease-in-out transform hover:scale-110">
                    <div class="w-12 h-12 rounded-full bg-gray-100 hover:bg-sky-900 flex items-center justify-center">
                        <i class="fab fa-facebook-f text-color-aidant-dark group-hover:text-white"></i>
                    </div>
                </a>
                <a href="" target="_blank" class="flex items-center group transition-all duration-300 ease-in-out transform hover:scale-110">
                    <div class="w-12 h-12 rounded-full bg-gray-100 hover:bg-sky-900 flex items-center justify-center">
                        <i class="fab fa-twitter text-color-aidant-dark group-hover:text-white"></i>
                    </div>
                </a>
                <a href="" target="_blank" class="flex items-center group transition-all duration-300 ease-in-out transform hover:scale-110">
                    <div class="w-12 h-12 rounded-full bg-gray-100 hover:bg-sky-900 flex items-center justify-center">
                        <i class="fab fa-linkedin-in text-color-aidant-dark group-hover:text-white"></i>
                    </div>
                </a>
            </div>
        </div>
    </div>
</footer>
