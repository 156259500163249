import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Tag } from '../../models/tag/tag';
import { ApiCommonService } from '../common/api-common.service';
import { environment } from '@lesaidants/env';
import { APICallReturn } from '../APICallReturn';

/**
 *@ignore
 */
@Injectable({
    providedIn: 'root',
})
/**
 * Class tag api service used for communication with back-end
 */
export class TagApiService extends ApiCommonService<Tag> {
    /**
     * URL to ping
     */
    private baseUrl = `${environment.url}/${environment.version}/tag`;
    protected resourceUrl = `${environment.url}/${environment.version}/tag`;

    /**
     * Method returning an Tag created from json data
     */
    fromJson(json: Tag): Tag {
        return Tag.fromJson(json);
    }
    /**
     * Method returning an Tag in a json format
     */
    toJson(model: Tag): Tag {
        return Tag.toJson(model);
    }
    /**
     * Method returning all Tags in Observable Tag Array
     */
    getTags(token: string): Observable<APICallReturn | Tag[] | undefined> {
        this.resourceUrl = `${this.baseUrl}/list`;
        return super.getAll(token);
    }
    /**
     * Method returning Observable Tag get by id
     */
    getTag(token: string, id: string): Observable<APICallReturn | Tag | undefined> {
        this.resourceUrl = `${this.baseUrl}/info`;
        return super.getById(token, id);
    }
    /**
     * Method returning Observable Tag updated by token
     */
    updateTag(token: string, id: string, tag: Tag): Observable<APICallReturn | Tag | undefined> {
        this.resourceUrl = `${this.baseUrl}/update/${id}`;
        return super.update(token, tag);
    }
    /**
     * Method returning Observable User create by auth token
     */
    createTag(tag: Tag): Observable<APICallReturn | Tag | undefined> {
        this.resourceUrl = `${this.baseUrl}/add`;
        return super.create(tag);
    }
    /**
     * Method returning to delete an ehpad
     */
    deleteTag(token: string, id: string): Observable<APICallReturn | Tag | undefined> {
        this.resourceUrl = `${this.baseUrl}/delete/${id}`;
        return super.delete(token);
    }
}
