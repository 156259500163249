import { FormGroup } from '@angular/forms';

export function FormValidator(form: FormGroup): boolean {
    const invalidFields: any[] = [];
    const controls = form.controls;

    Object.keys(controls).forEach((key) => {
        const control = controls[key];

        if (control instanceof FormGroup) {
            Object.keys(control.controls).forEach((innerKey) => {
                const innerControl = control.controls[innerKey];
                if (innerControl.invalid) {
                    invalidFields.push(`${key}.${innerKey}`);
                }
            });
        } else if (control.invalid) {
            invalidFields.push(key);
        }
    });
    console.log('État du formulaire:', form.value);
    if (invalidFields.length > 0) {
        console.log('Champs invalides:', invalidFields.join(', '));
    } else {
        console.log('Tous les champs sont valides');
    }
    return !!invalidFields.length;
}
