import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Meet } from '../../models/meet/meet';
import { APICallReturn } from '../APICallReturn';
import { MeetApiService } from './meet-api-service';
import { AuthService } from '../../auth';

@Injectable({
    providedIn: 'root',
})
export class MeetService {
    constructor(
        private meetApiService: MeetApiService,
        private authService: AuthService,
    ) {}

    public createMeet(token: string, meet: Meet): Observable<APICallReturn | Meet | undefined> {
        if (meet === undefined) {
            return of(undefined);
        }
        return this.meetApiService.createMeet(token, meet);
    }

    public updateMeet(token: string, meet: Partial<Meet>, id: string): Observable<APICallReturn | Meet | undefined> {
        if (meet === undefined) {
            return of(undefined);
        }
        return this.meetApiService.updateMeet(token, meet, id);
    }

    public getMeet(token: string, id: string): Observable<APICallReturn | Meet | undefined> {
        return this.meetApiService.getMeet(token, id);
    }

    public getMeets(token: string): Observable<APICallReturn | Meet[] | undefined> {
        return this.meetApiService.getMeets(token);
    }

    public deleteMeet(id: string): Observable<APICallReturn | Meet | undefined> {
        return this.meetApiService.deleteMeet(this.authService.getToken(), id);
    }
}
