<div class="mb-12 shadow">
    <h1 class="text-color-aidant-dark py-6 px-6 mb-6 font-bold text-3xl">{{ isNew ? title[0] : title[1] }}</h1>
</div>
<div class="w-full px-8 relative text-color-aidant-dark">
    <div class="flex flex-nowrap">
        <div *ngIf="meetForm" class="flex flex-col justify-center w-1/2">
            <div class="w-9/12">
                <form [formGroup]="this.meetForm.form">
                    <span class="p-float-label flex mb-10 items-center">
                        <input pInputText id="name" class="w-full" type="text" formControlName="name" />
                        <label for="name">Nom de l'événement</label>
                    </span>
                    <div class="flex mb-10 items-center space-x-4">
                    <span class="p-float-label w-full items-center">
                        <p-calendar  dateFormat="dd/mm/yy" [showTime]="true" hourFormat="24" styleClass="w-full"  inputId="calendar-start-date" formControlName="startDate"/>
                        <label for="startDate">Date de Début</label>
                    </span>
                    <span class="p-float-label w-full items-center">
                        <p-calendar  dateFormat="dd/mm/yy" [showTime]="true" hourFormat="24" styleClass="w-full"  inputId="calendar-end-date" formControlName="endDate"/>
                        <label for="endDate">Date de Fin</label>
                    </span>
                    </div>
                    <span class="p-field p-float-label mb-10 flex items-center">
                        <textarea pInputTextarea class="w-full" id="description" type="text" pInputText formControlName="description"></textarea>
                        <label for="description">Description</label>
                    </span>
                    <span class="mb-10 w-full p-float-label" >
                        <p-multiSelect styleClass="w-full" [(ngModel)]="this.selectedUsers" (onChange)="onPickUser()" inputId="multiselect" optionValue="mainUserId" [options]="inviteList" optionLabel="firstName" formControlName="inviteList" display="chip" [showClear]="true">
                            <ng-template let-user pTemplate="item">
                                <div class="flex flex-row">
                                    <img [alt]="user.label" src="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png" width="24" class="vertical-align-middle" />
                                    <span class="ml-1 mt-1">{{ user.firstName }} {{ user.lastName }}</span>
                                </div>
                            </ng-template>
                        </p-multiSelect>
                        <label for="multiselect">Invitations</label>
                    </span>
                    <span class="mb-10 flex items-start justify-center flex-col">
                        <p *ngIf="meetForm.form.get('isPrivate')?.value === true" class="text-lg font-normal text-color-aidant-dark">Privé</p>
                        <p *ngIf="meetForm.form.get('isPrivate')?.value === false" class="text-lg font-normal text-color-aidant-dark">Public</p>
                        <p-inputSwitch formControlName="isPrivate"></p-inputSwitch>
                    </span>
                <span class="mb-10 flex items-start justify-center flex-col">
                    <p class="text-lg font-normal text-color-aidant-dark">Présentiel</p>
                    <p-inputSwitch formControlName="isPhysical"></p-inputSwitch>
                </span>
                <span *ngIf="meetForm.form.get('isPhysical')?.value" class="p-float-label flex mb-10 items-center">
                    <input pInputText id="adress" class="w-full" type="text" formControlName="address" />
                    <label for="adress">Nom et numéro de voie</label>
                </span>
                <div *ngIf="meetForm.form.get('isPhysical')?.value" class="flex mb-10 items-center space-x-4">
                    <span class="p-float-label flex items-center w-1/2">
                        <input pInputText id="city" class="w-full" type="text" formControlName="city" />
                        <label for="city">Ville</label>
                    </span>
                    <span class="p-float-label flex items-center w-1/2">
                        <input pInputText id="zipCode" class="w-full" type="text" formControlName="zipCode" />
                        <label for="zipCode">Code postal</label>
                    </span>
                </div>
                <span *ngIf="meetForm.form.get('isPhysical')?.value"  class="p-field p-float-label mb-10 flex items-center">
                    <input id="participant" pInputText class="w-full" type="number" formControlName="maxUsers" />
                    <label for="participant">Nombre maximum de participants</label>
                </span>
                <span class="p-float-label w-full mb-10">
                    <p-dropdown styleClass="w-full text-left" inputId="category" [options]="categories" formControlName="category" optionLabel="name" optionValue="uuid"></p-dropdown>
                    <label for="category">Catégorie</label>
                </span>
                <span class="p-float-label w-full mb-10">
                    <p-multiSelect formGroupName="tags" styleClass="w-full" inputId="tags" [options]="tags" formControlName="tags" optionLabel="name" optionValue="uuid"></p-multiSelect>
                    <label for="tags">Tags</label>
                </span>
            </form>
        </div>
    </div>
    <div class="w-1/2 text-color-aidant-dark items-center pr-20">
    <!-- <div class="mb-14 flex flex-row items-end space-x-4">
        <div>
            <p class="font-bold mb-2 text-xl pb-4">Photo d'illustration</p>
            <label for="file-upload" class="flex flex-col justify-center text-center items-center w-auto h-32 bg-gray-50 border-2 border-dashed border-[#184E77] rounded-2xl cursor-pointer hover:bg-gray-100 p-2">
                <img src="../../../../assets/aidants_ressources/illustrations/upload-file.png" alt="Upload Icon" class="w-12 h-12">
                <span class="mt-2 text-sm"><span class="font-bold">Cliquez pour télécharger</span> ou glissez et déposez<br>SVG, PNG, JPG</span>
                <input type="file" id="file-upload" class="hidden" (change)="onFileSelected($event)" accept=".png, .jpg, .jpeg">
            </label>
        </div>
        <div>
            <img [src]="meetForm.form.get('image')?.value" class="w-auto h-32 rounded-2xl" />
        </div>
    </div> -->
    </div>
    </div>
    <div *ngIf="meetForm" class="flex justify-center items-center w-full mb-4 space-x-8">
        <button pButton label="Retour" class="p-button-raised p-button-secondary w-36" [routerLink]="['/meets']"></button>
        <button pButton label="Valider"  [loading]="isLoading" [disabled]="!meetForm.form.valid"  type="submit" class="p-button-raised w-36" (click)="onSubmit()"></button>
        <button *ngIf="!isNew" pButton label="Supprimer l'événement" class="p-button-raised p-button-danger w-36" (click)="delete()"></button>
    </div>
</div>
<p-toast position="bottom-center" [breakpoints]="{'1920px': {width: '350px'}}"></p-toast>