import { ApiCommonService } from '../common/api-common.service';
import { UserPermission } from '../../models/user-permission/user-permission';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '@lesaidants/env';
import { APICallReturn } from '../APICallReturn';

/**
 *@ignore
 */
@Injectable({
    providedIn: 'root',
})
/**
 * Class used to fill and send a UserPermission object
 */
export class UserPermissionApiService extends ApiCommonService<UserPermission> {
    /**
     * URL to ping
     */
    private baseUrl = `${environment.url}/${environment.version}/ehpad-permission`;
    protected resourceUrl = `${environment.url}/${environment.version}/ehpad-permission`;
    /**
     * Method returning a UserPermission created from json data
     */
    fromJson(json: any): UserPermission {
        return UserPermission.fromJson(json);
    }
    /**
     * Method returning any in a json format
     */
    toJson(model: UserPermission): any {
        return UserPermission.toJson(model);
    }
    /**
     * Method returning Observable UserPermission get by id
     */
    getUserPermissions(token: string, id: string): Observable<APICallReturn | UserPermission | undefined> {
        this.resourceUrl = `${this.baseUrl}/list`;
        return super.getById(token, id);
    }
    /**
     * Method returning Observable UserPermission update by auth token
     */
    updateUserPermissions(
        token: string,
        userPermission: UserPermission,
        id: string,
    ): Observable<APICallReturn | UserPermission | undefined> {
        this.resourceUrl = `${this.baseUrl}/update/${id}`;
        return super.update(token, userPermission);
    }
}
