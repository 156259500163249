import { User } from '../user/user';

export interface Coordinates {
    longitude: number;
    latitude: number;
}

/**
 * Class with the data for an ehpad
 */
export class Ehpad {
    /**
     *@ignore
     */
    constructor(
        public name?: string,
        public contactMail?: string, // à modifier en mail
        public phoneNumber?: string,
        public address?: string,
        public city?: string,
        public zipCode?: string,
        public imageUrl?: string,
        public logoUrl?: string,
        public availabilityPerMonth?: Record<string, number>,
        public zoneList?: Array<string>,
        public coordinates?: Coordinates,
        public contributors?: User[],
        public updatedAt?: Date,
        public status?: string,
        public id?: number,
        public uuid?: string,
    ) {}

    /**
     * Method returning an Ehpad created from json data
     */
    static fromJson(json: Partial<Ehpad>): Ehpad {
        return new Ehpad(
            json.name ?? undefined,
            json.contactMail ?? undefined,
            json.phoneNumber ?? undefined,
            json.address ?? undefined,
            json.city ?? undefined,
            json.zipCode ?? undefined,
            json.imageUrl ?? undefined,
            json.logoUrl ?? undefined,
            json.availabilityPerMonth ?? undefined,
            json.zoneList ?? undefined,
            json.coordinates ?? undefined,
            json.contributors ?? undefined,
            json.updatedAt ?? undefined,
            json.status ?? undefined,
            json.id ?? undefined,
            json.uuid ?? undefined,
        );
    }

    /**
     * Method returning Ehpad in a json format
     */
    static toJson(ehpad: Ehpad): Ehpad {
        return {
            name: ehpad.name ?? undefined,
            contactMail: ehpad.contactMail ?? undefined,
            phoneNumber: ehpad.phoneNumber ?? undefined,
            address: ehpad.address ?? undefined,
            city: ehpad.city ?? undefined,
            zipCode: ehpad.zipCode ?? undefined,
            imageUrl: ehpad.imageUrl ?? undefined,
            logoUrl: ehpad.logoUrl ?? undefined,
            availabilityPerMonth: ehpad.availabilityPerMonth ?? undefined,
            zoneList: ehpad.zoneList ?? undefined,
            coordinates: ehpad.coordinates ?? undefined,
            contributors: ehpad.contributors ?? undefined,
        };
    }
}
