<div *ngIf="!isLoading" class="flex">
    <div class="w-full p-10 mr-64 relative">
        <form [formGroup]="newsletterForm.form">
            <p-editor formControlName="content" [style]="{ height: '82vh' }">
                <ng-template pTemplate="header">
                    <span class="ql-formats">
                        <select class="ql-size mr-1">
                            <option value="small">Petit</option>
                            <option selected>Normal</option>
                            <option value="large">Large</option>
                        </select>
                        <button type="button" class="ql-bold mr-1" aria-label="Bold"></button>
                        <button type="button" class="ql-italic mr-1" aria-label="Italic"></button>
                        <button type="button" class="ql-underline mr-1" aria-label="Underline"></button>
                        <select class="ql-color mr-1"></select>
                        <select class="ql-background mr-1"></select>
                        <button type="button" class="ql-blockquote mr-1" aria-label="Blockquote"></button>
                        <button type="button" class="ql-list mr-1" value="ordered" aria-label="Ordered List"></button>
                        <button type="button" class="ql-list mr-1" value="bullet" aria-label="Bullet List"></button>
                        <button type="button" class="ql-indent mr-1" value="-1" aria-label="Decrease Indent"></button>
                        <button type="button" class="ql-indent mr-1" value="+1" aria-label="Increase Indent"></button>
                        <button type="button" class="ql-align mr-1" value="" aria-label="Align"></button>
                        <button type="button" class="ql-align mr-1" value="center" aria-label="Align Center"></button>
                        <button type="button" class="ql-align mr-1" value="right" aria-label="Align Right"></button>
                        <button type="button" class="ql-align mr-1" value="justify" aria-label="Justify"></button>
                        <button type="button" class="ql-link mr-1" aria-label="Insert Link"></button>
                        <button type="button" class="ql-image mr-1" aria-label="Insert Image"></button>
                        <button type="button" class="ql-video mr-1" aria-label="Insert Video"></button>
                    </span>
                </ng-template>
            </p-editor>
        </form>
    </div>
    <div class="p-5 h-full w-64 right-0 bg-white text-black shadow-md fixed">
        <div class="w-full">
            <p class="text-2xl text-center font-bold text-color-aidant-dark mt-6 mb-2 pb-4">Détails de l'article</p>
            <div class="flex items-center justify-center w-full mb-6">
                <div class="h-px bg-color-aidant-dark flex-grow"></div>
            </div>
            <div *ngIf="!isNew" class="mb-10 text-center">
                <a class="italic text-sm text-color-aidant-dark underline">Modifié le:</a>
                <a class="italic text-sm text-color-aidant-dark">&nbsp;{{ newsletter.updatedAt }}</a>
            </div>
        </div>
        <div class="flex flex-col text-center justify-center overflow-auto">
            <form [formGroup]="newsletterForm.form">
                <div class="mb-8 flex items-center">
                    <span class="p-float-label w-full">
                        <input pInputText id="name" class="w-full" type="name" formControlName="name" />
                        <label for="name">Titre</label>
                    </span>
                </div>
                <span class="p-float-label w-full mb-10">
                    <p-dropdown [options]="newsletterService.status" styleClass="w-full text-left" placeholder="Status" formControlName="status" />
                    <label for="status">Status</label>
                </span>
                <span class="p-float-label w-full mb-10">
                    <p-dropdown styleClass="w-full text-left" inputId="category" [options]="categories" formControlName="category" optionLabel="name" optionValue="uuid"></p-dropdown>
                    <label for="categoriesList">Catégorie</label>
                </span>
                <span class="p-float-label w-full mb-10">
                    <p-multiSelect styleClass="w-full" inputId="tags" [options]="tags" formControlName="tags" optionLabel="name" optionValue="uuid" display="chip" [showClear]="true"></p-multiSelect>
                    <label for="tagsList">Tags</label>
                </span>
                <div class="mb-10 flex items-center">
                    <span class="p-float-label w-full">
                        <input pInputText id="url" class="w-full" type="url" formControlName="url" />
                        <label for="url">Lien web</label>
                    </span>
                </div>
                <div class="mb-10 flex items-center">
                    <span class="p-float-label w-full">
                        <input pInputText id="url" class="w-full" type="url" formControlName="redirectUrl" />
                        <label for="url">Lien de redirection externe</label>
                    </span>
                </div>
                <div class="mb-2 flex justify-center items-center space-x-3">
                    <button pButton *ngIf="isPreview" label="Retour" class="p-button-raised p-button-secondary p-button-sm w-24" (click)="closePreview()"></button>
                    <button pButton label="Publier" type="submit" class="p-button-raised p-button-sm w-24" (click)="onSubmit()" [loading]="isLoading"></button>
                </div>
            </form>
        </div>
    </div>
</div>
<p-toast position="bottom-center" [breakpoints]="{'1920px': {width: '350px'}}"></p-toast>
