/**
 * Class with the data for an category
 */
export class Category {
    /**
     *@ignore
     */
    constructor(
        public name?: string,
        public color?: string,
        public guideIds?: Array<string>,
        public meetIds?: Array<string>,
        public updatedAt?: Date,
        public uuid?: string,
    ) {}

    /**
     * Method returning an Category created from json data
     */
    static fromJson(json: Partial<Category>): Category {
        return new Category(
            json.name ?? undefined,
            json.color ?? undefined,
            json.guideIds ?? undefined,
            json.meetIds ?? undefined,
            json.updatedAt ?? undefined,
            json.uuid ?? undefined,
        );
    }

    /**
     * Method returning Category in a json format
     */
    static toJson(category: Category): Category {
        return {
            name: category.name ?? undefined,
            color: category.color ?? undefined,
        };
    }
}
