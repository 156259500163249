<p-dialog styleClass="w-1/3" (onHide)="cancel()" [(visible)]="visible" [modal]="true" [draggable]="false" [resizable]="false">
    <p-header> {{ this.isNew ? this.modalTitle[0] : this.modalTitle[1] }} </p-header>
    <form [formGroup]="this.tagForm.form">
        <div class="mt-10 mb-8 flex items-center">
            <span class="p-float-label w-full">
                <input pInputText class="w-full" type="text" formControlName="name" />
                <label for="float-label">Nom</label>
            </span>
        </div>
        <div class="mb-10 flex items-center w-full">
            <span class="p-float-label w-full">
                <p-dropdown styleClass="w-full" [options]="colors" [showClear]="true" formControlName="color" optionLabel="name" optionValue="code" (onChange)="onChange($event)" appendTo="body">
                    <ng-template pTemplate="selectedItem">
                        <div class="flex gap-2" *ngIf="selectedColor">
                            <div [style.background-color]="selectedColor.code" style="min-width: 25px; border-radius: 25px;"></div>
                            <div>{{ selectedColor.name }}</div>
                        </div>
                    </ng-template>
                    <ng-template let-color pTemplate="item">
                        <div class="flex align-items-center gap-2">
                            <div [style.background-color]="color.code" style="min-width: 15px; max-height: 15px; margin-top: 4px; border-radius: 25px;"></div>
                            <div>{{ color.name }}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
                <label for="float-label">Couleur</label>
            </span>
        </div>
        <div *ngIf="tagForm" class="flex justify-center items-center w-full mb-4 space-x-8">
            <button pButton label="Annuler" class="p-button-raised p-button-secondary w-36" (click)="cancel()"></button>
            <button pButton label="Valider" [disabled]="!tagForm.form.valid" [loading]="isLoading" type="submit" class="p-button-raised w-36" (click)="onSubmitTag()"></button>
        </div>
    </form>
</p-dialog>
