import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { APICallReturn } from '../APICallReturn';
import { AuthService } from '../../auth';
import { CategoryApiService } from './category-api-service';
import { Category } from '../../models/category/category';

@Injectable({
    providedIn: 'root',
})
export class CategoryService {
    private categories: Category[] = [];

    constructor(
        private categoryApiService: CategoryApiService,
        private authService: AuthService,
    ) {}

    public get(): Category[] {
        return this.categories;
    }

    /* CALL APIS */

    public createCategory(category: Category): Observable<APICallReturn | Category | undefined> {
        if (category === undefined) {
            return of(undefined);
        }
        return this.categoryApiService.createCategory(this.authService.getToken(), category).pipe();
    }

    public updateCategory(id: string, category: Partial<Category>): Observable<APICallReturn | Category | undefined> {
        if (category === undefined) {
            return of(undefined);
        }
        return this.categoryApiService.updateCategory(this.authService.getToken(), id, category).pipe(
            map((data: APICallReturn | Category | undefined) => {
                if (data instanceof Category) {
                    const index = this.categories.findIndex((e) => e.uuid === data.uuid);

                    if (index !== -1) {
                        this.categories[index] = data;
                    }
                }
                return data;
            }),
        );
    }

    public getCategories(): Observable<APICallReturn | Category[] | undefined> {
        return this.categoryApiService.getCategories(this.authService.getToken());
    }

    public deleteCategory(id: string): Observable<APICallReturn | Category | undefined> {
        return this.categoryApiService.deleteCategory(this.authService.getToken(), id);
    }
}
