import { Component, Input, ViewChild } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Calendar, CalendarOptions, EventClickArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Meet } from '@lesaidantsbackoffice/core/models';
import { CalendarForm } from 'src/app/core/forms/calendar/calendar-form';
import { CalendarItem } from 'src/app/core/models/calendarItem/calendarItem';
import { CalendarItemService } from 'src/app/core/services/calendar-item/calendar-item.service';

@Component({
    selector: 'app-user-calendar-editor',
    templateUrl: './user-calendar-editor.component.html',
    styleUrls: ['./user-calendar-editor.component.scss'],
})
export class UserCalendarEditorComponent {
    @ViewChild('calendar') calendarComponent: FullCalendarComponent | undefined;
    @Input('id') id!: string;

    public XClick: number | undefined;
    public YClick: number | undefined;
    public modal: boolean = false;
    public firstTime: boolean = false;
    public selectItem: CalendarItem | undefined = undefined;
    public editable: boolean = false;
    public form: CalendarForm | undefined = undefined;
    public calendarItems: CalendarItem[] = [
        CalendarItem.fromJson({
            id: 1,
            uuid: 'uuid-1',
            color: '#ffffff',
            icon: 'icon-1',
            meet: Meet.fromJson({
                id: 101,
                uuid: 'meet-uuid-1',
                name: 'Meeting 1',
                description: 'Description for meeting 1',
                startDate: new Date('2024-07-01T10:00:00'),
                endDate: new Date('2024-07-01T11:00:00'),
            }),
            reminder: '30 minutes avant',
            acceptated: true,
        }),
        CalendarItem.fromJson({
            id: 2,
            uuid: 'uuid-2',
            color: '#abcabc',
            icon: 'icon-2',
            meet: Meet.fromJson({
                id: 102,
                uuid: 'meet-uuid-2',
                name: 'Meeting 2',
                description: 'Description for meeting 2',
                startDate: new Date('2024-07-15T10:00:00'),
                endDate: new Date('2024-07-15T11:00:00'),
            }),
            reminder: '1 heure avant',
            acceptated: false,
        }),
        CalendarItem.fromJson({
            id: 3,
            uuid: 'uuid-3',
            color: '#dddeee',
            icon: 'icon-3',
            meet: Meet.fromJson({
                id: 103,
                uuid: 'meet-uuid-3',
                name: 'Meeting 3',
                description: 'Description for meeting 3',
                startDate: new Date('2024-07-27T10:00:00'),
                endDate: new Date('2024-07-29T11:00:00'),
            }),
            reminder: '3 heures avant',
            acceptated: true,
        }),
    ];

    calendarOptions: CalendarOptions = {
        initialView: 'dayGridMonth',
        plugins: [dayGridPlugin, interactionPlugin],
        eventClick: (arg: EventClickArg) => this.handleEventClick(arg),
    };

    private convertDateToIso(date: Date | undefined): string {
        if (!date) return '';

        return (
            date.getFullYear() +
            '-' +
            ('0' + (date.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + date.getDate()).slice(-2) +
            'T' +
            ('0' + date.getHours()).slice(-2) +
            ':' +
            ('0' + date.getMinutes()).slice(-2) +
            ':' +
            ('0' + date.getSeconds()).slice(-2) +
            '.' +
            ('00' + date.getMilliseconds()).slice(-3)
        );
    }

    constructor(private calendarItem: CalendarItemService) {}

    ngAfterViewInit() {
        if (!this.id) return;
        let item: Calendar | undefined = this.calendarComponent?.getApi();

        this.calendarItems.forEach((r) => {
            item!.addEvent({
                title: r.meet?.name,
                start: this.convertDateToIso(r.meet?.startDate),
                end: this.convertDateToIso(r.meet?.endDate),
                color: r.color,
                id: r.id?.toString(),
            });
        });
        this.calendarItem.getCalendarItems(this.id).subscribe((r) => {
            if (Array.isArray(r) && r.length > 0 && r[0] instanceof CalendarItem) {
                this.calendarItems = r;
            }
        });
    }

    public rerenderEvents() {
        let item: Calendar | undefined = this.calendarComponent?.getApi();
        if (item) {
            item.removeAllEvents();
            this.calendarItems.forEach((r) => {
                item!.addEvent({
                    title: r.meet?.name,
                    start: this.convertDateToIso(r.meet?.startDate),
                    end: this.convertDateToIso(r.meet?.endDate),
                    color: r.color,
                    id: r.id?.toString(),
                });
            });
            item.render();
        }
    }

    public closeModal() {
        this.selectItem = undefined;
        this.editable = false;
        this.modal = false;
    }

    public getDate(): string {
        let returnedString = '';

        if (this.selectItem) {
            let startDate: Date = this.selectItem.meet?.startDate!;
            let endDate: Date = this.selectItem.meet?.endDate!;
            let startDateWithoutHours = new Date(
                `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`,
            ).getTime();
            let endDateWithoutHours = new Date(
                `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`,
            ).getTime();
            let isSameDay: boolean = startDateWithoutHours === endDateWithoutHours;

            if (isSameDay) {
                returnedString = `le ${this.formatDate(startDate, true)}`;
            } else {
                returnedString = `du ${this.formatDate(startDate, true)} au ${this.formatDate(endDate, true)}`;
            }
        }

        return returnedString;
    }

    private formatDate(date: Date, includeTime: boolean): string {
        const months = [
            'janvier',
            'février',
            'mars',
            'avril',
            'mai',
            'juin',
            'juillet',
            'août',
            'septembre',
            'octobre',
            'novembre',
            'décembre',
        ];
        const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

        const dayOfWeek = days[date.getDay()];
        const dayOfMonth = date.getDate();
        const month = months[date.getMonth()];

        let formattedDate = `${dayOfWeek} ${dayOfMonth} ${month}`;

        if (includeTime) {
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            formattedDate += ` à ${hours}:${minutes}`;
        }

        return formattedDate;
    }

    public isAcceptated(): boolean {
        return this.selectItem!.acceptated!;
    }

    handleEventClick(arg: EventClickArg) {
        let item: Calendar | undefined = this.calendarComponent?.getApi();

        this.modal = true;
        this.firstTime = true;
        if (item) {
            this.selectItem = this.calendarItems.find((r) => r.id === Number(arg.event.id));
            this.form = new CalendarForm();
            this.form.fromCalendar(this.calendarItems.find((r) => r.id === Number(arg.event.id))!);
        }
    }

    getWhere(event: any) {
        if (this.firstTime === false) return;
        const targetElement = event.target as HTMLDivElement;
        const rect = targetElement.getBoundingClientRect();

        this.XClick = rect.left + window.scrollX;
        this.YClick = rect.top + window.scrollY + rect.height + 5;
        this.firstTime = false;
    }

    public enable() {
        this.editable = true;
        this.form?.calendarForm.controls['color'].enable();
    }

    public updateEvent() {
        this.calendarItem.updateCalendarItem(this.form?.combineCalendar(this.selectItem!)!).subscribe((r) => {
            this.calendarItems = this.calendarItems.filter((item) => item.id !== this.selectItem?.id);
            this.calendarItems.push(this.form?.combineCalendar(this.selectItem!)!);
            this.closeModal();
            this.rerenderEvents();
        });
    }
}
