import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgOptimizedImage } from '@angular/common';
import { RouterModule } from '@angular/router';

/* PrimeNg */
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CarouselModule } from 'primeng/carousel';
import { TagModule } from 'primeng/tag';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { PasswordModule } from 'primeng/password';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { KeyFilterModule } from 'primeng/keyfilter';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DialogModule } from 'primeng/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { CalendarModule } from 'primeng/calendar';
import { TooltipModule } from 'primeng/tooltip';
import { CardModule } from 'primeng/card';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { MenuModule } from 'primeng/menu';
import { TableModule } from 'primeng/table';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FileUploadModule } from 'primeng/fileupload';
import { ColorPickerModule } from 'primeng/colorpicker';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { EditorModule } from 'primeng/editor';
import { FullCalendarModule } from '@fullcalendar/angular';

/* App Components */

import { NavbarComponent, FooterComponent } from '@lesaidantsbackoffice/components';
import {
    LoginComponent,
    GuidesComponent,
    UsersComponent,
    UserEditorComponent,
    EhpadsComponent,
    EhpadEditorComponent,
    DictionnaryComponent,
    TagListComponent,
    CategoryListComponent,
    GuideEditorComponent,
    NotFoundComponent,
    DashboardComponent,
    CategoryEditorComponent,
    TagEditorComponent,
    GuideListComponent,
    UserListComponent,
    EhpadListComponent,
    EhpadInformationEditorComponent,
    EhpadPlacesEditorComponent,
    ThreadsComponent,
    ThreadsEditorComponent,
    ThreadsListComponent,
    MeetsComponent,
    MeetEditorComponent,
    MeetListComponent,
    NewslettersComponent,
    NewsletterEditorComponent,
    NewsletterListComponent,
    UserInformationEditorComponent,
    UserProfessionalEditorComponent,
    UserCalendarEditorComponent,
} from '@lesaidantsbackoffice/pages';
import { CanvaThreadComponent } from './pages/threads/canva-thread/canva-thread.component';
import { BreadcrumbThreadComponent } from './pages/threads/breadcrumb-thread/breadcrumb-thread.component';
import { GeneralThreadComponent } from './pages/threads/general-thread/general-thread.component';
import { ThreadGenerationComponent } from './pages/threads/thread-generation/thread-generation.component';

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        FooterComponent,
        GuidesComponent,
        GuideEditorComponent,
        GuideListComponent,
        LoginComponent,
        NotFoundComponent,
        DashboardComponent,
        UsersComponent,
        UserEditorComponent,
        UserListComponent,
        EhpadsComponent,
        EhpadEditorComponent,
        EhpadListComponent,
        EhpadInformationEditorComponent,
        EhpadPlacesEditorComponent,
        DictionnaryComponent,
        TagListComponent,
        CategoryListComponent,
        CategoryEditorComponent,
        TagEditorComponent,
        EhpadInformationEditorComponent,
        EhpadPlacesEditorComponent,
        ThreadsComponent,
        ThreadsEditorComponent,
        ThreadsListComponent,
        MeetsComponent,
        MeetEditorComponent,
        MeetListComponent,
        NewslettersComponent,
        NewsletterEditorComponent,
        NewsletterListComponent,
        CanvaThreadComponent,
        BreadcrumbThreadComponent,
        GeneralThreadComponent,
        ThreadGenerationComponent,
        UserInformationEditorComponent,
        UserProfessionalEditorComponent,
        UserCalendarEditorComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        RouterModule,
        GoogleMapsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgOptimizedImage,
        CarouselModule,
        TagModule,
        ButtonModule,
        CheckboxModule,
        InputTextModule,
        DropdownModule,
        PasswordModule,
        KeyFilterModule,
        RadioButtonModule,
        InputTextareaModule,
        DialogModule,
        TooltipModule,
        CascadeSelectModule,
        CardModule,
        CalendarModule,
        InputNumberModule,
        ToastModule,
        MenuModule,
        OverlayPanelModule,
        TableModule,
        ProgressSpinnerModule,
        FileUploadModule,
        ColorPickerModule,
        InputSwitchModule,
        MultiSelectModule,
        BreadcrumbModule,
        FullCalendarModule,
        EditorModule,
    ],
    providers: [MessageService],
    bootstrap: [AppComponent],
})
export class AppModule {}
