<div class="h-full flex flex-col">
    <div class="select-none" *ngIf="!this.isCreating">
        <div class="mb-12 shadow">
            <h1 class="dark-color py-6 px-6 mb-6 font-bold text-3xl">Gestion du thread {{this.RootName ? this.RootName : this.actualID!}}</h1>
            <ul class="pl-6 flex items-center space-x-10 h-full">
                <a
                    (click)="this.isCanvaSide = false"
                    [class.active-link]="!this.isCanvaSide"
                    class="p-2 text-neutral-68 text-base font-medium transition-transform transform text-color-aidant-dark hover:text-[#0e3350] hover:scale-105 hover-link cursor-pointer -z-10"
                >
                    Fil d'ariane
                </a>
                <a
                    (click)="this.isCanvaSide = true"
                    [class.active-link]="this.isCanvaSide"
                    class="p-2 text-neutral-68 text-base font-medium transition-transform transform text-color-aidant-dark hover:text-[#0e3350] hover:scale-105 hover-link cursor-pointer -z-10"
                >
                    Caneva (WIP)
                </a>
            </ul>
        </div>
    </div>
    <app-breadcrumb-thread *ngIf="this.thread" [hidden]="this.isCanvaSide || this.isCreating" class="flex-1" [fillBreadCrumb]="this.FillBreadCrumb.bind(this)" [setCreatingToTrue]="this.setCreating.bind(this)" [menuItem]="this.menuItem" [thread]="this.thread"></app-breadcrumb-thread>
    <app-thread-generation *ngIf="this.isCreating" [thread]="this.thread" [setCreatingToFalse]="this.setCreating.bind(this)"></app-thread-generation>
</div>