import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { APICallReturn } from '../APICallReturn';
import { AuthService } from '../../auth';
import { NewsletterApiService } from './newsletter-api-service';
import { Newsletter } from '../../models/newsletter/newsletter';

@Injectable({
    providedIn: 'root',
})
export class NewsletterService {
    status: string[] = ['En rédaction', 'En validation', 'Validé'];

    constructor(
        private newsletterApiService: NewsletterApiService,
        private authService: AuthService,
    ) {}

    /* CALL APIS */

    public createNewsletter(token: string, newsletter: Newsletter): Observable<APICallReturn | Newsletter | undefined> {
        if (newsletter === undefined) {
            return of(undefined);
        }
        return this.newsletterApiService.createNewsletter(token, newsletter).pipe();
    }

    public updateNewsletter(
        token: string,
        newsletter: Partial<Newsletter>,
        id: string,
    ): Observable<APICallReturn | Newsletter | undefined> {
        if (newsletter === undefined) {
            return of(undefined);
        }
        return this.newsletterApiService.updateNewsletter(token, newsletter, id).pipe(
            map((data: APICallReturn | Newsletter | undefined) => {
                return data;
            }),
        );
    }

    public getNewsletter(id: string): Observable<APICallReturn | Newsletter | undefined> {
        return this.newsletterApiService.getNewsletter(this.authService.getToken(), id);
    }

    public getNewsletters(): Observable<APICallReturn | Newsletter[] | undefined> {
        return this.newsletterApiService.getNewsletters(this.authService.getToken());
    }

    public deleteNewsletter(id: string): Observable<APICallReturn | Newsletter | undefined> {
        return this.newsletterApiService.deleteNewsletter(this.authService.getToken(), id);
    }
}
