import { Component, Input, OnInit } from '@angular/core';
import { UserPermissionForm, UserProForm } from '@lesaidantsbackoffice/core/forms';
import { Ehpad, UserPermission, UserPro } from '@lesaidantsbackoffice/core/models';
import { EhpadService, UserPermissionService, UserProService } from '@lesaidantsbackoffice/core/services/services';
import { MessageService } from 'primeng/api';
import { catchError, finalize, map, of } from 'rxjs';
import { APICallReturn } from 'src/app/core/services/APICallReturn';

@Component({
    selector: 'app-user-professional-editor',
    templateUrl: './user-professional-editor.component.html',
    styleUrls: ['./user-professional-editor.component.scss'],
    providers: [MessageService],
})
export class UserProfessionalEditorComponent implements OnInit {
    @Input('id') id!: string;
    userProForm: UserProForm = new UserProForm();
    userPro: UserPro = new UserPro();
    userPermissionForm: UserPermissionForm = new UserPermissionForm();
    userPermission: UserPermission = new UserPermission();
    ehpads: Ehpad[] = [];
    isLoadingPro: boolean = false;
    isLoadingPermission: boolean = false;
    isLoadingEhpads: boolean = false;

    constructor(
        private messageService: MessageService,
        private userProService: UserProService,
        private userPermissionService: UserPermissionService,
        private ehpadService: EhpadService,
    ) {}

    ngOnInit(): void {
        this.isLoadingPro = true;
        this.isLoadingPermission = true;
        this.ehpadService
            .getEhpads()
            .pipe(
                map((ehpads: APICallReturn | Ehpad[] | undefined) => {
                    if (Array.isArray(ehpads) && ehpads.length > 0 && ehpads[0] instanceof Ehpad) {
                        this.ehpads = ehpads;
                    }
                }),
                finalize(() => (this.isLoadingEhpads = false)),
                catchError(() => {
                    this.isLoadingEhpads = false;
                    return of(null);
                }),
            )
            .subscribe();
        this.userPermissionService
            .getUserPermissions(this.id)
            .pipe(
                map((userPermission: APICallReturn | UserPermission | undefined) => {
                    if (userPermission instanceof UserPermission) {
                        this.userPermission = userPermission;
                        this.userPermissionForm.fromUserPermission(userPermission);
                    }
                }),
                finalize(() => (this.isLoadingPermission = false)),
                catchError(() => {
                    this.isLoadingPermission = false;
                    return of(null);
                }),
            )
            .subscribe();
        this.userProService
            .getUserPro(this.id)
            .pipe(
                map((userPro: APICallReturn | UserPro | undefined) => {
                    if (userPro instanceof UserPro) {
                        this.userPro = userPro;
                        this.userProForm.fromUserPro(userPro);
                    }
                }),
                finalize(() => (this.isLoadingPro = false)),
                catchError(() => {
                    this.isLoadingPro = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    edit() {
        this.isLoadingPro = true;
        this.userProService
            .updateUserPro(this.userProForm.toUserPro(), this.id)
            .pipe(
                map((userPro: APICallReturn | UserPro | undefined) => {
                    if (userPro instanceof UserPro) {
                        this.userPro = userPro;
                        this.userProForm.fromUserPro(userPro);
                        this.messageService.add({
                            severity: 'success',
                            detail: 'Utilisateur mis à jour',
                        });
                    }
                }),
                finalize(() => (this.isLoadingPro = false)),
                catchError(() => {
                    this.messageService.add({
                        severity: 'error',
                        detail: "Erreur lors de la mise à jour de l'utilisateur",
                    });
                    this.isLoadingPro = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    editPermissions() {
        this.isLoadingPermission = true;
        this.userPermissionService
            .updateUserPermissions(this.userPermissionForm.toUserPermission(), this.id)
            .pipe(
                map((userPermission: APICallReturn | UserPermission | undefined) => {
                    if (userPermission instanceof UserPermission) {
                        this.userPermission = userPermission;
                        this.userPermissionForm.fromUserPermission(userPermission);
                        this.messageService.add({
                            severity: 'success',
                            detail: 'Permissions mises à jour',
                        });
                    }
                }),
                finalize(() => (this.isLoadingPermission = false)),
                catchError(() => {
                    this.messageService.add({
                        severity: 'error',
                        detail: 'Erreur lors de la mise à jour des permissions',
                    });
                    this.isLoadingPermission = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    delete() {
        this.isLoadingPro = true;
        this.userProService
            .deleteUserPro(this.userPro.userId?.toString() || '')
            .pipe(
                map((userPro: APICallReturn | UserPro | undefined) => {
                    if (userPro instanceof UserPro) {
                        this.userPro = new UserPro();
                        this.userProForm.fromUserPro(userPro);
                        this.messageService.add({
                            severity: 'success',
                            detail: 'Compte professionnel supprimé',
                        });
                    }
                }),
                finalize(() => (this.isLoadingPro = false)),
                catchError(() => {
                    this.messageService.add({
                        severity: 'error',
                        detail: 'Erreur lors de la suppression du compte professionnel',
                    });
                    this.isLoadingPro = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    onSubmitPro(): void {
        if (this.userProForm?.form.valid) {
            this.edit();
        } else {
            this.userProForm?.markAsDirty();
        }
    }

    onSubmitPermissions(): void {
        this.editPermissions();
    }

    onDelete(): void {
        this.delete();
    }
}
