import { Meet } from '../../models/meet/meet';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Tag } from '../../models/tag/tag';
export class MeetForm {
    public meetForm: FormGroup;

    constructor() {
        let fb: FormBuilder = new FormBuilder();
        this.meetForm = fb.group({
            inviteList: [undefined],
            name: [undefined, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
            description: [undefined, [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
            image: [undefined],
            category: [undefined],
            tags: [undefined],
            startDate: [undefined, Validators.required],
            endDate: [undefined, Validators.required],
            isPrivate: [false],
            isPhysical: [false],
            address: [undefined],
            city: [undefined],
            zipCode: [undefined],
            maxUsers: [undefined],
        });

        this.meetForm.get('isPhysical')?.valueChanges.subscribe((value) => {
            const addressControl = this.meetForm.get('address');
            addressControl?.setValidators(value ? [Validators.required] : null);
            if (!value) {
                addressControl?.reset();
            }
            addressControl?.updateValueAndValidity();

            const maxUsersControl = this.meetForm.get('maxUsers');
            maxUsersControl?.setValidators(value ? [Validators.required] : null);
            if (!value) {
                maxUsersControl?.reset();
            }
            maxUsersControl?.updateValueAndValidity();
        });

        this.meetForm.get('isPrivate')?.valueChanges.subscribe((value) => {
            const invitationControl = this.meetForm.get('invitation');
            invitationControl?.setValidators(value ? [Validators.required] : null);
            if (!value) {
                invitationControl?.reset();
            }
            invitationControl?.updateValueAndValidity();
        });
    }

    get form(): FormGroup {
        return this.meetForm;
    }

    markAsDirty(): void {
        Object.keys(this.form.controls).forEach((key) => {
            const control = this.form.get(key);

            if (control instanceof FormGroup) {
                this.markAsDirty();
            } else {
                if (control && !control.valid) {
                    control.markAsDirty();
                }
            }
        });
    }

    resetForm(): void {
        this.meetForm.reset();
    }

    fromMeet(meet: Meet): void {
        if (meet) {
            this.meetForm.patchValue({
                inviteList: meet.inviteList || undefined,
                name: meet.name || undefined,
                description: meet.description || undefined,
                image: meet.image || undefined,
                category: meet.category?.uuid || undefined,
                tags: meet.tags?.map((tag: Tag) => tag.uuid) || undefined,
                startDate: meet.startDate ? new Date(new Date(meet.startDate).getTime()) : null,
                endDate: meet.endDate ? new Date(new Date(meet.endDate).getTime()) : null,
                isPrivate: meet.isPrivate || undefined,
                isPhysical: meet.isPhysical || undefined,
                address: meet.address || undefined,
                city: meet.city || undefined,
                zipcode: meet.zipCode || undefined,
                maxUsers: meet.maxUsers || undefined,
            });
        }
    }

    toMeet(): Meet {
        const formValue = this.meetForm.value;
        return new Meet(
            formValue.inviteList || [],
            formValue.name,
            formValue.description,
            formValue.image,
            formValue.category,
            formValue.tags,
            new Date(new Date(formValue.startDate).getTime() - new Date().getTimezoneOffset() * 60000),
            new Date(new Date(formValue.endDate).getTime() - new Date().getTimezoneOffset() * 60000),
            formValue.isPrivate,
            formValue.isPhysical,
            formValue.address,
            formValue.city,
            formValue.zipCode,
            formValue.maxUsers,
        );
    }
}
