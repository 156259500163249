<div class="w-full px-8 relative">
    <div class="flex flex-nowrap">
        <div *ngIf="this.ehpadForm" class="flex flex-col justify-center w-1/2">
            <div class="w-9/12">
                <form [formGroup]="this.ehpadForm.ehpadForm">
                    <div formGroupName="availabilityPerMonth">
                        <div *ngFor="let month of monthes; let i = index" class="p-float-label flex mb-10 items-center">
                            <input pInputText id="month-{{i}}" type="number" [formControlName]="month.digit.toString()" />
                            <label for="month-{{i}}">{{ month.name }}</label>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div *ngIf="ehpadForm" class="flex justify-center items-center w-full mb-4 space-x-8">
        <button pButton label="Annuler" class="p-button-raised p-button-secondary w-36" (click)="cancel()"></button>
        <button pButton label="Valider" [disabled]="!ehpadForm.form.get('availabilityPerMonth')?.valid" [loading]="isLoading" type="submit" class="p-button-raised w-36" (click)="onSubmit()"></button>
    </div>
</div>
