import { Injectable } from "@angular/core";
import { ApiCommonService } from "../common/api-common.service";
import { Thread } from "../../models/thread/thread";
import { environment } from "@lesaidants/env";
import { Observable, of } from "rxjs";
import { APICallReturn } from "../APICallReturn";
import { User } from "../../models/user/user";


@Injectable({
    providedIn: 'root',
})
export class ThreadApiService extends ApiCommonService<Thread> {
    private baseUrl = `${environment.url}/${environment.version}/chat-bot`;
    protected resourceUrl = `${environment.url}/${environment.version}/chat-bot`;
    
    fromJson(json: Thread): Thread {
        return Thread.fromJson(json);
    }

    toJson(model: Thread): Thread {
        return Thread.toJson(model);
    }

    getThreads(token: string): Observable<APICallReturn | Thread[] | undefined> {
        this.resourceUrl = `${this.baseUrl}/list`;
        return super.getAll(token);
    }

    getFirstThreads(token: string): Observable<APICallReturn | Thread[] | undefined> {
        this.resourceUrl = `${this.baseUrl}/list/first`;
        return super.getAll(token);
    }

    getThread(token: string, uuid: string): Observable<APICallReturn | Thread | undefined> {
        this.resourceUrl = `${this.baseUrl}/info`;
        return super.getById(token, uuid);
    }
    
    deleteThread(token: string, uuid: string): Observable<APICallReturn | Thread> {
        this.resourceUrl = `${this.baseUrl}/delete/${uuid}`;
        return super.delete(token);
    }
    
    updateThread(token: string, thread: Thread, uuid: string): Observable<APICallReturn | Thread | undefined> {
        this.resourceUrl = `${this.baseUrl}/update/${uuid}`;
        return super.update(token, thread);
    }

    createThread(token: string, thread: Thread): Observable<APICallReturn | Thread | undefined> {
        this.resourceUrl = `${this.baseUrl}/add`;
        return super.create(thread, token);
    }


}
