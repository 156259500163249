<div class="w-full flex justify-center pb-10">
    <div class="card">
        <p-table
            #meetsTable
            [value]="meets"
            [paginator]="true"
            dataKey="id"
            [rows]="10"
            [showCurrentPageReport]="true"
            [loading]="isLoading"
            [tableStyle]="{ 'min-width': '70rem' }"
            currentPageReportTemplate="Total {totalRecords}"
            [globalFilterFields]="['name']"
            [rowsPerPageOptions]="[5, 10, 20]"
            [selectionMode]="'single'"
        >
            <ng-template pTemplate="caption">
                <div class="flex-start">
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" [(ngModel)]="search" (input)="meetsTable.filterGlobal(search, 'contains')" placeholder="Rechercher un événement" class="w-72" />
                    </span>
                    <p-button class="right-5 absolute" (onClick)="create()">Créer</p-button>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Nom</th>
                    <th>Description</th>
                    <th>Restriction</th>
                    <th>Adresse</th>
                    <th>Présentiel</th>
                    <th>Places disponibles</th>
                    <th>Début</th>
                    <th>Fin</th>
                    <!-- <th>Invitations</th> -->
                    <!-- <th style="width: fill-available;" pSortableColumn="updatedAt" class="flex justify-content-center align-items-center">
                        Date de modification
                        <p-sortIcon field="updatedAt"></p-sortIcon>
                    </th> -->
                    <th style="width: 5%;"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-meet>
                <tr class="cursor-pointer" (click)="edit(meet.uuid)">
                    <td>{{ meet.name }}</td>
                    <td>{{ meet.description }}</td>
                    <td>
                        <div *ngIf="meet.isPrivate" class="w-7 h-7 rounded-full flex items-center justify-center">
                            Privé
                        </div>
                        <div *ngIf="!meet.isPrivate" class="w-7 h-7 rounded-full flex items-center justify-center">
                            Public
                        </div>
                    </td>
                    <td>{{ meet.address }} {{ meet.zipCode }} {{ meet.city }}</td>
                    <td>
                        <div *ngIf="meet.isPhysical" class="bg-green-500 text-white w-7 h-7 rounded-full flex items-center justify-center">
                            <i class="pi pi-check"></i>
                        </div>
                        <div *ngIf="!meet.isPhysical" class="bg-red-500 text-white w-7 h-7 rounded-full flex items-center justify-center">
                            <i class="pi pi-times"></i>
                        </div>
                    </td>
                    <!-- <td>{{ meet.invitations ? meet.invitations.length : 0 }}</td> -->
                    <td>{{ meet.maxUsers }}</td>
                    <td>{{ meet.startDate | date }}</td>
                    <td>{{ meet.endDate | date }}</td>
                    <!-- <td>{{ meet.updatedAt | date }}</td> -->
                    <td (click)="$event.stopPropagation(); delete(meet.uuid)">
                        <div class="p-4 items-center flex justify-center rounded-full cursor-pointer hover:bg-red-500 hover:text-white">
                            <i class="pi pi-trash cursor-pointer"></i>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

</div>
