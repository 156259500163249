import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserForm } from '@lesaidantsbackoffice/core/forms';
import { User } from '@lesaidantsbackoffice/core/models';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-user-editor',
    templateUrl: './user-editor.component.html',
    styleUrls: ['./user-editor.component.scss'],
    providers: [MessageService],
})
export class UserEditorComponent implements OnInit {
    userForm: UserForm = new UserForm();
    isNew: boolean = true;
    id?: string;
    isLoading: boolean = false;
    selectedPage: 'informations' | 'calendar' | 'user-pro' = 'informations';
    title: string[] = ['Créer un compte', 'Modifier un compte'];

    constructor(private activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe((params) => {
            this.id = params.get('id') || 'new';
            this.isNew = this.id === 'new';
            console.log('HERE DUDE', this.isNew, this.id);
        });
    }

    setSelectedPage(str: 'informations' | 'calendar' | 'user-pro') {
        this.selectedPage = str;
    }
}
