<div class="flex items-center justify-center h-screen w-full">
    <div class="absolute right-24 top-10 w-28 h-28 bg-[#093455] shadow-lg rounded-2xl p-6 floaty">
    </div>
    <div class="absolute right-28 top-10 w-24 h-24 transform translate-x-2 translate-y-2 bg-white shadow-lg rounded-2xl p-2 floaty-slow">
        <img src="../../../assets/aidants_ressources/illustrations/healthcare.png" alt="Description" class="w-full h-full object-cover rounded-2xl">
    </div>
    <div class="absolute right-1/4 bottom-20 w-28 h-28 bg-gray-1 shadow-lg rounded-full p-6 floaty-slow">
    </div>
    <div class="absolute right-[26%] bottom-24 w-20 h-20 transform translate-x-2 translate-y-2 bg-[#093455] shadow-lg rounded-full p-2 floaty-slow">
        <img src="../../../assets/aidants_ressources/illustrations/medical-team.png" alt="Description" class="w-full h-full object-cover">
    </div>
    <div class="absolute left-48 top-40 w-24 h-24 bg-gray-1 bg-[#093455] shadow-lg rounded-full p-6 floaty">
    </div>
    <div class="absolute left-52 top-40 w-20 h-20 transform translate-x-2 translate-y-2 bg-white shadow-lg rounded-full p-2 floaty-slow">
        <img src="../../../assets/aidants_ressources/illustrations/speech-bubble.png" alt="Description" class="w-full h-full object-cover">
    </div>
    <div class="flex flex-col justify-center items-center text-center">
        <div class="flex mb-20 text-5xl font-extrabold text-color-aidant-dark">Les-<div class="text-color-aidant-light">Aidants</div><div class="text-3xl pt-3 font-normal italic">&nbsp;partner</div></div>
        <form [formGroup]="this.userForm.form">
            <div class="mb-4 flex items-center">
                <input pInputText class="w-72" placeholder="Email" type="email" formControlName="email" />
            </div>
            <div class="mb-10 flex items-center">
                <p-password id="password" type="password" class="w-full" [feedback]="false" [toggleMask]="true" placeholder="Mot de Passe" formControlName="password"
                styleClass="p-password p-component p-inputwrapper p-input-icon-right" [style]="{'width':'100%'}"
                [inputStyle]="{'width':'100%'}"></p-password>
            </div>
            <div class="mb-2 flex justify-center">
                <button pButton label="Valider" type="submit" class="p-button-raised p-button-secondary w-36" (click)="onSubmit()" [loading]="isLoading"></button>
            </div>
        </form>
    </div>
    <p-toast position="bottom-center" [breakpoints]="{'1920px': {width: '350px'}}"></p-toast>
</div>
