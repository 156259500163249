interface City {
    name: string;
    code: string;
}

export const Cities: City[] = [
    { name: 'Art-sur-Meurthe', code: 'Art-sur-Meurthe' },
    { name: 'Bainville-sur-Madon', code: 'Bainville-sur-Madon' },
    { name: 'Burthecourt-aux-Chênes', code: 'Burthecourt-aux-Chênes' },
    { name: 'Champigneulles', code: 'Champigneulles' },
    { name: 'Chaligny', code: 'Chaligny' },
    { name: 'Custines', code: 'Custines' },
    { name: 'Dombasle-sur-Meurthe', code: 'Dombasle-sur-Meurthe' },
    { name: 'Dommartemont', code: 'Dommartemont' },
    { name: 'Essey-lès-Nancy', code: 'Essey-lès-Nancy' },
    { name: 'Flavigny-sur-Moselle', code: 'Flavigny-sur-Moselle' },
    { name: 'Fléville-devant-Nancy', code: 'Fléville-devant-Nancy' },
    { name: 'Heillecourt', code: 'Heillecourt' },
    { name: 'Houdemont', code: 'Houdemont' },
    { name: 'Jarville-la-Malgrange', code: 'Jarville-la-Malgrange' },
    { name: 'Laneuveville', code: 'Laneuveville' },
    { name: 'Laxou', code: 'Laxou' },
    { name: 'Ludres', code: 'Ludres' },
    { name: 'Maizières-les-Metz', code: 'Maizières-les-Metz' },
    { name: 'Malzéville', code: 'Malzéville' },
    { name: 'Maxéville', code: 'Maxéville' },
    { name: 'Méréville', code: 'Méréville' },
    { name: 'Messein', code: 'Messein' },
    { name: 'Nancy', code: 'Nancy' },
    { name: 'Neuves-Maisons', code: 'Neuves-Maisons' },
    { name: 'Pont-à-Mousson', code: 'Pont-à-Mousson' },
    { name: 'Pont-Saint-Vincent', code: 'Pont-Saint-Vincent' },
    { name: 'Pulnoy', code: 'Pulnoy' },
    { name: 'Pulligny', code: 'Pulligny' },
    { name: 'Richardménil', code: 'Richardménil' },
    { name: 'Rosières-Aux-Salines', code: 'Rosières-Aux-Salines' },
    { name: 'Saint-Max', code: 'Saint-Max' },
    { name: 'Saulxures-lès-Nancy', code: 'Saulxures-lès-Nancy' },
    { name: 'Seichamps', code: 'Seichamps' },
    { name: 'St-Nicolas-De-Port', code: 'St-Nicolas-De-Port' },
    { name: 'Tomblaine', code: 'Tomblaine' },
    { name: 'Vandoeuvre-lès-Nancy', code: 'Vandoeuvre-lès-Nancy' },
    { name: 'Varangéville', code: 'Varangéville' },
    { name: 'Velaine-sous-Amance', code: 'Velaine-sous-Amance' },
    { name: 'Villers-lès-Nancy', code: 'Villers-lès-Nancy' },
    { name: 'Xeuilley', code: 'Xeuilley' },
];