<div class="mb-12 shadow">
    <h1 class="text-color-aidant-dark py-6 px-6 mb-6 font-bold text-3xl">{{ isNew ? title[0] : title[1] }}</h1>
    <ul class="pl-6 flex items-center space-x-10 h-full">
        <a
            (click)="setSelectedPage('informations')"
            [class.active-link]="selectedPage === 'informations'"
            class="p-2 text-neutral-68 text-base font-medium transition-transform transform text-color-aidant-dark hover:text-[#0e3350] hover:scale-105 hover-link cursor-pointer"
        >
            Informations
        </a>
        <a
            *ngIf="!isNew"
            (click)="setSelectedPage('calendar')"
            [class.active-link]="selectedPage === 'calendar'"
            class="p-2 text-neutral-68 text-base font-medium transition-transform transform text-color-aidant-dark hover:text-[#0e3350] hover:scale-105 hover-link cursor-pointer"
        >
            Calendrier
        </a>
        <a
            *ngIf="!isNew"
            (click)="setSelectedPage('user-pro')"
            [class.active-link]="selectedPage === 'user-pro'"
            class="p-2 text-neutral-68 text-base font-medium transition-transform transform text-color-aidant-dark hover:text-[#0e3350] hover:scale-105 hover-link cursor-pointer"
        >
            Espace professsionel
        </a>
    </ul>
</div>
<app-user-information-editor *ngIf="this.id && this.selectedPage === 'informations'" [id]="this.id || ''"></app-user-information-editor>
<app-user-professional-editor *ngIf="this.id && this.selectedPage === 'user-pro'" [id]="this.id || ''"></app-user-professional-editor>
<app-user-calendar-editor *ngIf="this.id && this.selectedPage === 'calendar'" [id]="this.id || ''"></app-user-calendar-editor>
<p-toast></p-toast>