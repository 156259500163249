<div class="flex flex-col">
    <div class="card flex flex-col justify-content-center px-8">
        <p-breadcrumb 
            class="mb-8 cursor-pointer" 
            [model]="this.menuItem">
        </p-breadcrumb>
    </div>
    <div class="flex-1 px-32 pb-32 pt-16 flex">
        <div class=" w-full flex flex-col p-16 pt-6 border-2 shadow rounded-3xl">
            <div class="flex justify-center items-center pt-4 pb-8 flex-col">
                <h1 class="text-center dark-color font-bold text-xl mb-4">Message du thread</h1>
                <textarea 
                    class="w-1/3 mb-4"
                    rows="5" 
                    pInputTextarea
                    [(ngModel)]="this.thread.message">
                </textarea>
                <div class="flex w-full justify-end"><p-button (click)="this.saveThread()">Sauvegarder le message</p-button></div>
            </div>
            <div [ngClass]="{ 'h-px' : this.thread.threads && this.thread.threads.length > 0}" class="h-px w-full bg-[#e5e7eb] mb-20"></div>
            <h1 class="text-center dark-color font-bold text-xl">{{ this.thread.threads && this.thread.threads.length > 0 ? "Choisissez votre réponse afin de naviguer :" : "Aucune réponse créée à ce thread"}}</h1>
            <div class="flex justify-center flex-wrap" *ngIf="this.thread && this.thread.threads">
                <div *ngFor="let item of this.thread.threads" class="flex flex-col items-center justify-center w-1/2  sm:w-full md:w-1/2 xl:w-1/3 min-w-[100px]">
                    <div class="box-border border-2 border-gray-200 rounded-lg my-6 p-4 flex items-center justify-between space-x-4 w-[300px] min-w-[100px]">
                        <div class="flex gap-4 items-center">
                            <i class="pi pi-times-circle cursor-pointer" (click)="this.deleteThread(item)"></i>
                            <p>{{ item.choice }}</p>
                        </div>
                        <p-button class="p-button-raised p-button-sm w-fit" icon="pi pi-chevron-right" (click)="this.fillBreadCrumb(item.threadUUID, true, false)"></p-button>
                    </div>
                </div>
            </div>
            <div [ngClass]="{ 'h-px' : this.thread.threads && this.thread.threads.length > 0}" class="h-px w-full bg-[#e5e7eb] mb-20"></div>
            <div class="flex w-full justify-end"><p-button (click)="this.setCreatingToTrue(true)">Ajouter une réponse</p-button></div>
        </div>
    </div>
    <div>

    </div>
</div>