export class User {
    constructor(
        public firstName?: string,
        public lastName?: string,
        public email?: string,
        public password?: string,
        public phoneNumber?: string,
        public address?: string,
        public city?: string,
        public zipCode?: string,
        public status?: string,
        public isValidated?: boolean,
        public updatedAt?: Date,
        public resetCode?: string,
        public id?: number,
        public uuid?: string,
        public mainUserId?: number,
    ) {}

    static updateFromJson(toFill: Partial<User>, filler: Partial<User>): User {
        return Object.assign(toFill, filler) as User;
    }

    static fromJson(json: Partial<User>): User {
        return new User(
            json.firstName ?? undefined,
            json.lastName ?? undefined,
            json.email ?? undefined,
            json.password ?? undefined,
            json.phoneNumber ?? undefined,
            json.address ?? undefined,
            json.city ?? undefined,
            json.zipCode ?? undefined,
            json.status ?? undefined,
            json.isValidated ?? undefined,
            json.updatedAt ?? undefined,
            json.resetCode ?? undefined,
            json.id ?? undefined,
            json.uuid ?? undefined,
            json.mainUserId ?? undefined,
        );
    }

    static toJson(user: User): Partial<User> {
        return {
            firstName: user.firstName ?? undefined,
            lastName: user.lastName ?? undefined,
            email: user.email ?? undefined,
            password: user.password ?? undefined,
            phoneNumber: user.phoneNumber ?? undefined,
            address: user.address ?? undefined,
            city: user.city ?? undefined,
            zipCode: user.zipCode ?? undefined,
            status: user.status ?? undefined,
            isValidated: user.isValidated ?? undefined,
            mainUserId: user.mainUserId ?? undefined,
        };
    }
}
