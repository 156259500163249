import { Component, Input, OnInit } from '@angular/core';
import { TagForm } from '@lesaidantsbackoffice/core/forms';
import { ColorType, Colors } from '@lesaidantsbackoffice/core/objects';

@Component({
    selector: 'app-tag-editor',
    templateUrl: './tag-editor.component.html',
    styleUrls: ['./tag-editor.component.scss'],
})
export class TagEditorComponent implements OnInit {
    @Input() isNew = false;
    @Input() isLoading = false;
    @Input() tagForm!: TagForm;
    @Input() cancel!: () => void;
    @Input() onSubmit!: () => void;
    modalTitle: Array<string> = ['Créer un tag', 'Modifier un tag'];
    visible: boolean = true;
    colors = Colors;
    selectedColor: ColorType | null = null;

    constructor() {}

    ngOnInit(): void {
        this.selectedColor = this.colors.find((color) => color.code === this.tagForm.form.get('color')?.value) || null;
    }

    onSubmitTag(): void {
        this.onSubmit();
    }

    onChange(event: any): void {
        this.selectedColor = this.colors.find((color) => color.code === event.value) || null;
    }
}
