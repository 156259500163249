export interface child {
    choice: string,
    threadUUID: string
}

export class Thread {
    constructor(
        public title?: string, // Bulle clicable (ex: êtes vous aidants)
        public message?: string, // Bloc description à la réponse
        public previous?: string,
        public id?: number,
        public uuid?: string,
        public threads?: child[],
        // public index?: number, // Position de l'éléments dans la liste de threads
        // public threads?: Thread[],
        // public createdAt?: Date,
        // public updatedAt?: Date,
        // public createdBy?: User,
        // public iterationLength?: number
    ) {}

    static fromJson(json: Partial<Thread>): Thread {
        return new Thread(
            json.title ?? undefined,
            json.message ?? undefined,
            json.previous ?? undefined,
            json.id ?? undefined,
            json.uuid ?? undefined,
            // json.index ?? undefined,
            json.threads ?? undefined,
            // json.threads ?? undefined,
            // json.createdAt ?? undefined,
            // json.updatedAt ?? undefined,
            // json.createdBy ?? undefined,
            // json.iterationLength ?? undefined,
        );
    }

    static toJson(thread: Thread): Thread {
        return {
            id: thread.id ?? undefined,
            uuid: thread.uuid ?? undefined,
            title: thread.title ?? undefined,
            message: thread.message ?? undefined,
            previous: thread.previous ?? undefined,
            threads: thread.threads ?? undefined,
            // index: thread.index ?? undefined,
            // threads: thread.threads ?? undefined,
            // createdAt: thread.createdAt ?? undefined,
            // updatedAt: thread.updatedAt ?? undefined,
            // createdBy: thread.createdBy ?? undefined,
            // iterationLength: thread.iterationLength ?? undefined
        };
    }
}
