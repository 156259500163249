import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Guide } from '../../models/guide/guide';
import { ApiCommonService } from '../common/api-common.service';
import { environment } from '@lesaidants/env';
import { APICallReturn } from '../APICallReturn';

/**
 *@ignore
 */
@Injectable({
    providedIn: 'root',
})
/**
 * Class guide api service used for communication with back-end
 */
export class GuideApiService extends ApiCommonService<Guide> {
    /**
     * URL to ping
     */
    private baseUrl = `${environment.url}/${environment.version}/guide`;
    protected resourceUrl = `${environment.url}/${environment.version}/guide`;

    /**
     * Method returning an Guide created from json data
     */
    fromJson(json: Guide): Guide {
        return Guide.fromJson(json);
    }
    /**
     * Method returning an Guide in a json format
     */
    toJson(model: Guide): Guide {
        return Guide.toJson(model);
    }
    /**
     * Method returning all Guides in Observable Guide Array
     */
    getGuides(token: string): Observable<APICallReturn | Guide[] | undefined> {
        this.resourceUrl = `${this.baseUrl}/list`;
        return super.getAll(token);
    }
    /**
     * Method returning Observable Guide get by id
     */
    getGuide(token: string, id: string): Observable<APICallReturn | Guide | undefined> {
        this.resourceUrl = `${this.baseUrl}/info`;
        return super.getById(token, id);
    }
    /**
     * Method returning Observable Guide updated by token
     */
    updateGuide(token: string, guide: Guide, id: string): Observable<APICallReturn | Guide | undefined> {
        this.resourceUrl = `${this.baseUrl}/update/${id}`;
        return super.update(token, guide);
    }
    /**
     * Method returning Observable User create by auth token
     */
    createGuide(token: string, guide: Guide): Observable<APICallReturn | Guide | undefined> {
        this.resourceUrl = `${this.baseUrl}/add`;
        return super.create(guide, token);
    }
    /**
     * Method returning to delete an ehpad
     */
    deleteGuide(token: string, id: string): Observable<APICallReturn | Guide | undefined> {
        this.resourceUrl = `${this.baseUrl}/delete/${id}`;
        return super.delete(token);
    }
}
