import { Component, Input, OnInit } from '@angular/core';
import { CategoryForm } from '@lesaidantsbackoffice/core/forms';
import { ColorType, Colors } from '@lesaidantsbackoffice/core/objects';

@Component({
    selector: 'app-category-editor',
    templateUrl: './category-editor.component.html',
    styleUrls: ['./category-editor.component.scss'],
})
export class CategoryEditorComponent implements OnInit {
    @Input() isNew = false;
    @Input() isLoading = false;
    @Input() categoryForm!: CategoryForm;
    @Input() cancel!: () => void;
    @Input() onSubmit!: () => void;
    modalTitle: Array<string> = ['Créer une catégorie', 'Modifier une catégorie'];
    visible: boolean = true;
    colors = Colors;
    selectedColor: ColorType | null = null;

    constructor() {}

    ngOnInit(): void {
        this.selectedColor =
            this.colors.find((color) => color.code === this.categoryForm.form.get('color')?.value) || null;
    }

    onSubmitCategory(): void {
        this.onSubmit();
    }

    onChange(event: any): void {
        this.selectedColor = this.colors.find((color) => color.code === event.value) || null;
    }
}
