import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EhpadForm } from '@lesaidantsbackoffice/core/forms';
import { EhpadService } from '@lesaidantsbackoffice/core/services/services';
import { AuthService } from 'src/app/core/auth';
import { APICallReturn } from 'src/app/core/services/APICallReturn';
import { Ehpad } from '@lesaidantsbackoffice/core/models';
import { catchError, finalize, map, of } from 'rxjs';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-ehpad-editor',
    templateUrl: './ehpad-editor.component.html',
    styleUrls: ['./ehpad-editor.component.scss'],
})
export class EhpadEditorComponent {
    ehpadForm: EhpadForm = new EhpadForm();
    ehpad: Ehpad = new Ehpad();
    id?: string;
    isNew: boolean = false;
    isLoading: boolean = false;
    show: boolean = true;
    urlEhpad: any;
    selectedPage: 'etablissement' | 'place-accueil' = 'etablissement';
    title: string[] = ['Créer un établissement', "Modifier l'établissement"];

    constructor(
        private activatedRoute: ActivatedRoute,
        public ehpadService: EhpadService,
        private authService: AuthService,
        private messageService: MessageService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe((params) => {
            this.id = params.get('id') || 'new';
            this.isNew = this.id === 'new';
        });

        if (!this.isNew) {
            this.fetchEhpad();
        }
    }

    cancel(): void {
        this.ehpadForm.resetForm();
        this.fetchEhpad();
    }

    onSubmit(): void {
        this.isLoading = true;

        if (this.isNew) {
            this.ehpadService
                .createEhpad(this.ehpadForm.toEhpad())
                .pipe(
                    map((ehpad: APICallReturn | Ehpad | undefined) => {
                        if (ehpad instanceof Ehpad) {
                            this.messageService.add({
                                severity: 'success',
                                detail: 'Établissement créé',
                            });
                            this.id = ehpad.uuid;
                            this.router.navigate([`ehpad/${this.id}`]);
                        }
                    }),
                    finalize(() => {
                        this.ehpadForm.resetForm();
                        this.isLoading = false;
                    }),
                    catchError(() => {
                        this.isLoading = false;
                        return of(null);
                    }),
                )
                .subscribe();
        } else {
            this.ehpadService
                .updateEhpad(this.id || '', this.ehpadForm.toEhpad())
                .pipe(
                    map((ehpad: APICallReturn | Ehpad | undefined) => {
                        if (ehpad instanceof Ehpad) {
                            this.messageService.add({
                                severity: 'success',
                                detail: 'Établissement mis à jour',
                            });
                            this.ehpadForm.resetForm();
                            this.fetchEhpad();
                        }
                    }),
                    finalize(() => {
                        this.isLoading = false;
                    }),
                    catchError(() => {
                        this.isLoading = false;
                        return of(null);
                    }),
                )
                .subscribe();
        }
    }

    setSelectedPage(str: 'etablissement' | 'place-accueil') {
        this.selectedPage = str;
    }

    private fetchEhpad(): void {
        this.isLoading = true;
        this.ehpadService
            .getEhpad(this.id || '')
            .pipe(
                map((ehpad: APICallReturn | Ehpad | undefined) => {
                    if (ehpad instanceof Ehpad) {
                        this.ehpad = ehpad;
                        this.ehpadForm.fromEhpad(ehpad);
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }
}
