import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { APICallReturn } from '../APICallReturn';
import { AuthService } from '../../auth';
import { Invite } from '../../models/invite/invite';
import { InviteApiService } from './invite-api-service';

@Injectable({
    providedIn: 'root',
})
export class InviteService {
    constructor(
        private inviteApiService: InviteApiService,
        private authService: AuthService,
    ) {}

    public createInvite(token: string, invite: Invite): Observable<APICallReturn | Invite | undefined> {
        if (invite === undefined) {
            return of(undefined);
        }
        return this.inviteApiService.createInvite(token, invite).pipe();
    }

    public getInvites(token: string): Observable<APICallReturn | Invite[] | undefined> {
        return this.inviteApiService.getInvites(token);
    }

    public deleteInvite(id: string): Observable<APICallReturn | Invite | undefined> {
        return this.inviteApiService.deleteInvite(this.authService.getToken(), id);
    }
}
