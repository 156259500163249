import { Category } from '../category/category';
import { Tag } from '../tag/tag';

/**
 * Class with the data for an newsletter
 */
export class Newsletter {
    /**
     *@ignore
     */
    constructor(
        public name?: string,
        public status?: string,
        public category?: Category,
        public tags?: Tag[],
        public content?: string,
        public url?: string,
        public redirectUrl?: string,
        public updatedAt?: Date,
        public id?: number,
        public uuid?: string,
    ) {}

    /**
     * Method returning an Newsletter created from json data
     */
    static fromJson(json: Partial<Newsletter>): Newsletter {
        return new Newsletter(
            json.name ?? undefined,
            json.status ?? undefined,
            json.category ?? undefined,
            json.tags ?? undefined,
            json.content ?? undefined,
            json.url ?? undefined,
            json.redirectUrl ?? undefined,
            json.updatedAt ?? undefined,
            json.id ?? undefined,
            json.uuid ?? undefined,
        );
    }

    /**
     * Method returning Newsletter in a json format
     */
    static toJson(newsletter: Newsletter): Newsletter {
        return {
            name: newsletter.name ?? undefined,
            status: newsletter.status ?? undefined,
            category: newsletter.category ?? undefined,
            tags: newsletter.tags ?? undefined,
            content: newsletter.content ?? undefined,
            url: newsletter.url ?? undefined,
            redirectUrl: newsletter.redirectUrl ?? undefined,
        };
    }
}
