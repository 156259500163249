import { Injectable } from "@angular/core";
import { ThreadApiService } from "./thread-api-service";
import { Thread } from "../../models/thread/thread";
import { Observable, of } from "rxjs";
import { APICallReturn } from "../APICallReturn";

@Injectable({
  providedIn: 'root',
})
export class ThreadService {
  constructor(private threadApiService: ThreadApiService) {}

  public createThread(token: string, thread: Thread): Observable<APICallReturn | Thread | undefined> {
    if (thread === undefined) {
      return of(undefined);;
    }
    return this.threadApiService.createThread(token, thread);
  }

  public updateThread(token: string, thread: Partial<Thread>, id: string): Observable<APICallReturn | Thread | undefined> {
    if (thread === undefined) {
      return of(undefined);
    }
    return this.threadApiService.updateThread(token, thread, id);
  }

  public getThread(token: string, id: string): Observable<APICallReturn | Thread | undefined> {
    return this.threadApiService.getThread(token, id);
  }

  public getThreads(token: string): Observable<APICallReturn | Thread[] | undefined> {
    return this.threadApiService.getThreads(token);
  }

  public getFirstThreads(token: string): Observable<APICallReturn | Thread[] | undefined> {
    return this.threadApiService.getFirstThreads(token);
  }

  public deleteThread(token: string, id: string): Observable<APICallReturn | Thread> {
    return this.threadApiService.deleteThread(token, id);
  }
}
