<div class="w-full px-8 relative">
    <div class="flex flex-nowrap">
        <div *ngIf="ehpadForm" class="flex flex-col justify-center w-1/2">
            <div class="w-9/12">
                <form [formGroup]="this.ehpadForm.form">
                    <span class="p-float-label flex mb-10 items-center">
                        <input pInputText id="name" class="w-full" type="text" formControlName="name" />
                        <label for="name">Nom de l'établissement</label>
                    </span>
                    <span class="p-float-label flex mb-10 items-center">
                        <input pInputText id="contactMail" class="w-full" type="text" formControlName="contactMail" />
                        <label for="contactMail">Email de contact</label>
                    </span>
                    <span class="p-float-label flex mb-10 items-center">
                        <input pInputText id="phoneNumber" class="w-full" type="text" formControlName="phoneNumber" />
                        <label for="phoneNumber">Téléphone</label>
                    </span>
                    <span class="p-float-label flex mb-10 items-center">
                        <input pInputText id="adress" class="w-full" type="text" formControlName="address" />
                        <label for="adress">Nom et numéro de voie</label>
                    </span>
                    <div class="flex mb-10 items-center space-x-4">
                        <span class="p-float-label flex items-center w-1/2">
                            <input pInputText id="city" class="w-full" type="text" formControlName="city" />
                            <label for="city">Ville</label>
                        </span>
                        <span class="p-float-label flex items-center w-1/2">
                            <input pInputText id="zipCode" class="w-full" type="text" formControlName="zipCode" />
                            <label for="zipCode">Code postal</label>
                        </span>
                    </div>
                    <span class="p-float-label w-full mb-10">
                        <p-multiSelect styleClass="w-full" inputId="zoneList" [options]="cities" formControlName="zoneList" optionLabel="name" optionValue="code" display="chip" [showClear]="true"></p-multiSelect>
                        <label for="zoneList">Zones de ramassage</label>
                    </span>
                    <div formGroupName="coordinates" class="flex mb-10 items-center space-x-4">
                        <span class="p-float-label flex items-center w-1/2">
                            <input pInputText id="latitude" class="w-full" type="text" formControlName="latitude" />
                            <label for="latitude">Latitude</label>
                        </span>
                        <span class="p-float-label flex items-center w-1/2">
                            <input pInputText id="longitude" class="w-full" type="text" formControlName="longitude" />
                            <label for="longitude">Longitude</label>
                        </span>
                    </div>
                </form>
            </div>
        </div>
        <div class="w-1/2 text-color-aidant-dark items-center pr-20">
            <div class="mb-14 flex flex-row items-end space-x-4">
                <div>
                    <p class="font-bold mb-2 text-xl pb-4">Photo de l'établissement</p>
                    <label for="file-upload" class="flex flex-col justify-center text-center items-center w-auto h-32 bg-gray-50 border-2 border-dashed border-[#184E77] rounded-2xl cursor-pointer hover:bg-gray-100 p-2">
                        <img src="../../../../assets/aidants_ressources/illustrations/upload-file.png" alt="Upload Icon" class="w-12 h-12">
                        <span class="mt-2 text-sm"><span class="font-bold">Cliquez pour télécharger</span> ou glissez et déposez<br>SVG, PNG, JPG</span>
                        <input type="file" id="file-upload" class="hidden" (change)="onFileSelected($event, 'imageUrl')" accept=".png, .jpg, .jpeg">
                    </label>
                </div>
                <div>
                    <img [src]="ehpadForm.form.get('imageUrl')?.value" class="w-auto h-32 rounded-2xl" />
                </div>
            </div>
            <div class="mb-14 flex flex-row items-end space-x-4">
                <div>
                    <p class="font-bold mb-2 text-xl pb-4">Logo de l'établissement</p>
                    <label for="file-upload-2" class="flex flex-col justify-center text-center items-center w-auto h-32 bg-gray-50 border-2 border-dashed border-[#184E77] rounded-2xl cursor-pointer hover:bg-gray-100 p-2">
                        <img src="../../../../assets/aidants_ressources/illustrations/upload-file.png" alt="Upload Icon" class="w-12 h-12">
                        <span class="mt-2 text-sm"><span class="font-bold">Cliquez pour télécharger</span> ou glissez et déposez<br>SVG, PNG, JPG</span>
                        <input type="file" id="file-upload-2" class="hidden" (change)="onFileSelected($event, 'logoUrl')" accept=".png, .jpg, .jpeg">
                    </label>
                </div>
                <div>
                    <img [src]="ehpadForm.form.get('logoUrl')?.value" class="w-auto h-32 rounded-2xl" />
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="ehpadForm" class="flex justify-center items-center w-full mb-4 space-x-8">
        <button pButton label="Annuler" class="p-button-raised p-button-secondary w-36" (click)="cancel()"></button>
        <button pButton label="Valider" [disabled]="!ehpadForm.form.valid" [loading]="isLoading" type="submit" class="p-button-raised w-36" (click)="onSubmit()"></button>
    </div>
</div>
