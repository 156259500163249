import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Tag } from '../../models/tag/tag';

export class TagForm {
    public tagForm: FormGroup;

    constructor() {
        let fb: FormBuilder = new FormBuilder();

        this.tagForm = fb.group({
            name: [undefined, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
            color: [undefined, [Validators.required, Validators.minLength(3), Validators.maxLength(11)]],
        });
    }

    get form(): FormGroup {
        return this.tagForm;
    }

    markAsDirty(): void {
        Object.keys(this.form.controls).forEach((key) => {
            const control = this.form.get(key);

            if (control instanceof FormGroup) {
                this.markAsDirty();
            } else {
                if (control && !control.valid) {
                    control.markAsDirty();
                }
            }
        });
    }

    resetForm(): void {
        this.tagForm.reset();
    }

    fromTag(tag: Tag): void {
        if (tag) {
            this.tagForm.patchValue({
                name: tag.name || undefined,
                color: tag.color || undefined,
            });
        }
    }

    toTag(): Tag {
        const formValue = this.tagForm.value;
        return new Tag(formValue.name, formValue.color);
    }
}
