import { Category } from '../category/category';
import { Tag } from '../tag/tag';
import { User } from '../user/user';

/**
 * Class with the data for an guide
 */
export class Guide {
    /**
     *@ignore
     */
    constructor(
        public name?: string,
        public status?: string,
        public category?: Category,
        public tags?: Tag[],
        public content?: string,
        public url?: string,
        public contributors?: User[],
        public like?: number,
        public dislike?: number,
        public updatedAt?: Date,
        public id?: number,
        public uuid?: string,
    ) {}

    /**
     * Method returning an Guide created from json data
     */
    static fromJson(json: Partial<Guide>): Guide {
        return new Guide(
            json.name ?? undefined,
            json.status ?? undefined,
            json.category ?? undefined,
            json.tags ?? undefined,
            json.content ?? undefined,
            json.url ?? undefined,
            json.contributors ?? undefined,
            json.like ?? undefined,
            json.dislike ?? undefined,
            json.updatedAt ?? undefined,
            json.id ?? undefined,
            json.uuid ?? undefined,
        );
    }

    /**
     * Method returning Guide in a json format
     */
    static toJson(guide: Guide): Guide {
        return {
            name: guide.name ?? undefined,
            status: guide.status ?? undefined,
            category: guide.category ?? undefined,
            tags: guide.tags ?? undefined,
            content: guide.content ?? undefined,
            url: guide.url ?? undefined,
            contributors: guide.contributors ?? undefined,
            like: guide.like ?? undefined,
            dislike: guide.dislike ?? undefined,
        };
    }
}
