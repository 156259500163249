import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GuideForm } from '@lesaidantsbackoffice/core/forms';
import { CategoryService, GuideService, TagService } from '@lesaidantsbackoffice/core/services/services';
import { AuthService } from 'src/app/core/auth';
import { Category, Guide, Tag } from '@lesaidantsbackoffice/core/models';
import { catchError, finalize, map, of } from 'rxjs';
import { MessageService } from 'primeng/api';
import { APICallReturn } from 'src/app/core/services/APICallReturn';

@Component({
    selector: 'app-guide-editor',
    templateUrl: './guide-editor.component.html',
    styleUrls: ['./guide-editor.component.scss'],
})
export class GuideEditorComponent {
    isPreview: boolean = false;
    guideForm: GuideForm = new GuideForm();
    guide: Guide = new Guide();
    categories: Category[] = [];
    tags: Tag[] = [];
    id?: string;
    isNew: boolean = false;
    isLoading: boolean = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        public guideService: GuideService,
        private categoryService: CategoryService,
        private tagService: TagService,
        private authService: AuthService,
        private messageService: MessageService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe((params) => {
            this.id = params.get('id') || 'new';
            this.isNew = this.id === 'new';
        });

        if (!this.isNew) {
            this.isLoading = true;
            this.guideService
                .getGuide(this.id || '')
                .pipe(
                    map((guide: APICallReturn | Guide | undefined) => {
                        if (guide instanceof Guide) {
                            this.guide = guide;
                            this.guideForm.fromGuide(guide);
                        }
                    }),
                    finalize(() => (this.isLoading = false)),
                    catchError(() => {
                        this.isLoading = false;
                        this.router.navigate([`404`]);
                        return of(null);
                    }),
                )
                .subscribe();
        }
        this.fetchTags();
        this.fetchCategories();
    }

    openPreview(): void {
        this.isPreview = true;
    }

    closePreview(): void {
        this.isPreview = false;
    }

    create(): void {
        this.isLoading = true;
        this.guideService
            .createGuide(this.authService.getToken(), this.guideForm.toGuide())
            .pipe(
                map((guide: APICallReturn | Guide | undefined) => {
                    if (guide instanceof Guide) {
                        this.router.navigate([`guide/${guide.uuid}`]);
                        this.messageService.add({
                            severity: 'success',
                            detail: 'Guide créé',
                        });
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.messageService.add({
                        severity: 'error',
                        detail: 'Erreur lors de la création du guide',
                    });
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    edit(): void {
        this.isLoading = true;
        this.guideService
            .updateGuide(this.authService.getToken(), this.guideForm.toGuide(), this.id || '')
            .pipe(
                map((guide: APICallReturn | Guide | undefined) => {
                    if (guide instanceof Guide) {
                        this.guide = guide;
                        this.guideForm.fromGuide(guide);
                        this.messageService.add({
                            severity: 'success',
                            detail: 'Guide mis à jour',
                        });
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.messageService.add({
                        severity: 'error',
                        detail: 'Erreur lors de la mise à jour du guide',
                    });
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    onSubmit(): void {
        if (this.guideForm?.form.valid && this.isNew) {
            this.create();
        } else if (this.guideForm?.form.valid) {
            this.edit();
        } else {
            this.guideForm?.markAsDirty();
        }
    }

    private fetchTags(): void {
        this.isLoading = true;
        this.tagService
            .getTags()
            .pipe(
                map((tags: APICallReturn | Tag[] | undefined) => {
                    if (Array.isArray(tags) && tags.length > 0 && tags[0] instanceof Tag) {
                        this.tags = tags;
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    private fetchCategories(): void {
        this.isLoading = true;
        this.categoryService
            .getCategories()
            .pipe(
                map((categories: APICallReturn | Category[] | undefined) => {
                    if (Array.isArray(categories) && categories.length > 0 && categories[0] instanceof Category) {
                        this.categories = categories;
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }
}
