<div class="mb-12 shadow">
    <h1 class="text-color-aidant-dark py-6 px-6 mb-6 font-bold text-3xl">Gestion du dictionnaire</h1>
    <ul class="pl-6 flex items-center space-x-10 h-full">
        <a
            (click)="setSelectedPage('categories')"
            [class.active-link]="selectedPage === 'categories'"
            class="p-2 text-neutral-68 text-base font-medium transition-transform transform text-color-aidant-dark hover:text-[#0e3350] hover:scale-105 hover-link cursor-pointer"
        >
            Catégories
        </a>
        <a
            (click)="setSelectedPage('tags')"
            [class.active-link]="selectedPage === 'tags'"
            class="p-2 text-neutral-68 text-base font-medium transition-transform transform text-color-aidant-dark hover:text-[#0e3350] hover:scale-105 hover-link cursor-pointer"
        >
            Tags
        </a>
    </ul>
</div>
<app-category-list *ngIf="this.selectedPage === 'categories'"></app-category-list>
<app-tag-list *ngIf="this.selectedPage === 'tags'"></app-tag-list>
