import { Category } from '../category/category';
import { Tag } from '../tag/tag';

export interface Invite {
    id?: number;
    uuid?: string;
    userUuid?: string;
    permission?: boolean;
    maxUse?: number;
    IdMeet?: number;
    createdAt?: Date;
}

export class Meet {
    constructor(
        public inviteList?: Number[],
        public name?: string,
        public description?: string,
        public image?: string,
        public category?: Category,
        public tags?: Tag[],
        public startDate?: Date,
        public endDate?: Date,
        public isPrivate?: boolean,
        public isPhysical?: boolean,
        public address?: string,
        public city?: string,
        public zipCode?: string,
        public maxUsers?: number,
        public id?: number,
        public uuid?: string,
        public createdAt?: Date,
    ) {}

    /**
     * Method returning Meet in a json format
     */
    static fromJson(json: Partial<Meet>): Meet {
        return new Meet(
            json.inviteList ?? undefined,
            json.name ?? undefined,
            json.description ?? undefined,
            json.image ?? undefined,
            json.category ?? undefined,
            json.tags ?? undefined,
            json.startDate ?? undefined,
            json.endDate ?? undefined,
            json.isPrivate ?? undefined,
            json.isPhysical ?? undefined,
            json.address ?? undefined,
            json.city ?? undefined,
            json.zipCode ?? undefined,
            json.maxUsers ?? undefined,
            json.id ?? undefined,
            json.uuid ?? undefined,
            json.createdAt ?? undefined,
        );
    }
    static toJson(meet: Meet): Partial<Meet> {
        return {
            inviteList: meet.inviteList ?? undefined,
            name: meet.name ?? undefined,
            description: meet.description ?? undefined,
            image: meet.image ?? undefined,
            category: meet.category ?? undefined,
            tags: meet.tags ?? undefined,
            startDate: meet.startDate ?? undefined,
            endDate: meet.endDate ?? undefined,
            isPrivate: meet.isPrivate ?? undefined,
            isPhysical: meet.isPhysical ?? undefined,
            address: meet.address ?? undefined,
            city: meet.city ?? undefined,
            zipCode: meet.zipCode ?? undefined,
            maxUsers: meet.maxUsers ?? undefined,
            createdAt: meet.createdAt ?? undefined,
        };
    }
}
