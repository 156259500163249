import { Injectable } from '@angular/core';
import { environment } from '@lesaidants/env';
import { Meet } from '../../models/meet/meet';
import { APICallReturn } from '../APICallReturn';
import { Observable } from 'rxjs';
import { ApiCommonService } from '../common/api-common.service';

@Injectable({
    providedIn: 'root',
})
export class MeetApiService extends ApiCommonService<Meet> {
    private baseUrl = `${environment.url}/${environment.version}/meet`;
    protected resourceUrl = `${environment.url}/${environment.version}/meet`;

    fromJson(json: Meet): Meet {
        return Meet.fromJson(json);
    }

    toJson(model: Meet): Meet {
        return Meet.toJson(model);
    }

    getMeets(token: string): Observable<APICallReturn | Meet[] | undefined> {
        this.resourceUrl = `${this.baseUrl}/list`;
        return super.getAll(token);
    }

    getMeet(token: string, id: string): Observable<APICallReturn | Meet | undefined> {
        this.resourceUrl = `${this.baseUrl}/info`;
        return super.getById(token, id);
    }

    createMeet(token: string, meet: Meet): Observable<APICallReturn | Meet | undefined> {
        this.resourceUrl = `${this.baseUrl}/add`;
        return super.create(meet, token);
    }

    updateMeet(token: string, meet: Meet, id: string): Observable<APICallReturn | Meet | undefined> {
        this.resourceUrl = `${this.baseUrl}/update/${id}`;
        return super.update(token, meet);
    }

    deleteMeet(token: string, id: string): Observable<APICallReturn | Meet | undefined> {
        this.resourceUrl = `${this.baseUrl}/delete/${id}`;
        return super.delete(token);
    }
}
