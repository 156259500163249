export interface Month {
    name:
        | 'Janvier'
        | 'Février'
        | 'Mars'
        | 'Avril'
        | 'Mai'
        | 'Juin'
        | 'Juillet'
        | 'Août'
        | 'Septembre'
        | 'Octobre'
        | 'Novembre'
        | 'Décembre';
    digit: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

export const Monthes: Month[] = [
    { name: 'Janvier', digit: 1 },
    { name: 'Février', digit: 2 },
    { name: 'Mars', digit: 3 },
    { name: 'Avril', digit: 4 },
    { name: 'Mai', digit: 5 },
    { name: 'Juin', digit: 6 },
    { name: 'Juillet', digit: 7 },
    { name: 'Août', digit: 8 },
    { name: 'Septembre', digit: 9 },
    { name: 'Octobre', digit: 10 },
    { name: 'Novembre', digit: 11 },
    { name: 'Décembre', digit: 12 },
];
