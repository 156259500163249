import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CalendarItem } from '../../models/calendarItem/calendarItem';

export class CalendarForm {
    public calendarForm: FormGroup;
    public reminders: string[] = ['pas de rappel', '30 minutes avant', '1 heure avant', '3 heures avant', '12 heures avant', '1 jour avant'];

    constructor() {
        let fb: FormBuilder = new FormBuilder();

        this.calendarForm = fb.group({
            color: [undefined, [Validators.required]],
            icon: [undefined, [Validators.required]],
            reminder: [undefined, [Validators.required]],
        });
    }

    get form(): FormGroup {
        return this.calendarForm;
    }

    markAsDirty(): void {
        Object.keys(this.form.controls).forEach((key) => {
            const control = this.form.get(key);

            if (control instanceof FormGroup) {
                this.markAsDirty();
            } else {
                if (control && !control.valid) {
                    control.markAsDirty();
                }
            }
        });
    }

    resetForm(): void {
        this.calendarForm.reset();
    }

    fromCalendar(calendarItem: CalendarItem): void {
        if (calendarItem) {
            this.calendarForm.patchValue({
                color: calendarItem.color || undefined,
                icon: calendarItem.icon || undefined,
                reminder: calendarItem.reminder || undefined,
            });
        }
        this.calendarForm.get("color")!.disable();
    }

    combineCalendar(calendar: CalendarItem): CalendarItem {
        const formValue = this.calendarForm.value;

        return {...calendar, color: formValue.color, icon: formValue.icon, reminder: formValue.reminder}
    }
}
