import { Component, OnInit } from '@angular/core';
import { CategoryForm } from '@lesaidantsbackoffice/core/forms';
import { Category } from '@lesaidantsbackoffice/core/models';
import { CategoryService } from '@lesaidantsbackoffice/core/services/services';
import { MessageService } from 'primeng/api';
import { catchError, finalize, map, of } from 'rxjs';
import { APICallReturn } from 'src/app/core/services/APICallReturn';

@Component({
    selector: 'app-category-list',
    templateUrl: './category-list.component.html',
    styleUrls: ['./category-list.component.scss'],
})
export class CategoryListComponent implements OnInit {
    search: string = '';
    categoryForm: CategoryForm = new CategoryForm();
    isNew: boolean = false;
    isOpen: boolean = false;
    isLoading = false;
    categories: Category[] = [];
    selectedCategoryId: string | undefined;

    constructor(
        private categoryService: CategoryService,
        private messageService: MessageService,
    ) {}

    ngOnInit(): void {
        this.fetchCategories();
    }

    create(): void {
        this.isOpen = true;
        this.isNew = true;
    }

    edit(category: Category) {
        this.selectedCategoryId = category.uuid;
        this.categoryForm.fromCategory(category);
        this.isOpen = true;
        this.isNew = false;
    }

    delete(id: string): void {
        this.categoryService
            .deleteCategory(id)
            .pipe(
                map(() => {
                    this.messageService.add({
                        severity: 'success',
                        detail: 'Catégorie supprimée',
                    });
                    this.fetchCategories();
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    refreshList() {}

    cancel(): void {
        this.categoryForm.resetForm();
        this.isOpen = false;
    }

    onSubmit(): void {
        this.isLoading = true;

        if (this.isNew) {
            this.categoryService
                .createCategory(this.categoryForm.toCategory())
                .pipe(
                    map((category: APICallReturn | Category | undefined) => {
                        if (category instanceof Category) {
                            this.messageService.add({
                                severity: 'success',
                                detail: 'Catégorie créée',
                            });
                            this.fetchCategories();
                            this.isOpen = false;
                        }
                    }),
                    finalize(() => {
                        this.categoryForm.resetForm();
                        this.isLoading = false;
                    }),
                    catchError(() => {
                        this.isLoading = false;
                        return of(null);
                    }),
                )
                .subscribe();
        } else {
            this.categoryService
                .updateCategory(this.selectedCategoryId || '', this.categoryForm.toCategory())
                .pipe(
                    map((category: APICallReturn | Category | undefined) => {
                        if (category instanceof Category) {
                            this.messageService.add({
                                severity: 'success',
                                detail: 'Catégorie mise à jour',
                            });
                            this.fetchCategories();
                            this.isOpen = false;
                        }
                    }),
                    finalize(() => {
                        this.categoryForm.resetForm();
                        this.isLoading = false;
                    }),
                    catchError(() => {
                        this.isLoading = false;
                        return of(null);
                    }),
                )
                .subscribe();
        }
    }

    private fetchCategories(): void {
        this.isLoading = true;
        this.categoryService
            .getCategories()
            .pipe(
                map((categories: APICallReturn | Category[] | undefined) => {
                    if (Array.isArray(categories) && categories.length > 0 && categories[0] instanceof Category) {
                        this.categories = categories;
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }
}
