import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@lesaidantsbackoffice/core/services/services';
import { MenuItem } from 'primeng/api';
import { AuthService } from 'src/app/core/auth';
import { User } from 'src/app/core/models';

@Component({
    selector: 'navbar-component',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
    @Input() showFullInterface!: boolean;
    public user: User = new User();
    items!: MenuItem[];
    toggleMenu!: MenuItem[];

    constructor(
        public router: Router,
        public userService: UserService,
    ) {}
}
