import { Injectable } from '@angular/core';
import { CalendarItem } from '../../models/calendarItem/calendarItem';
import { ApiCommonService } from '../common/api-common.service';
import { environment } from '@lesaidants/env';

@Injectable({
    providedIn: 'root',
})
export class CalendarItemApiService extends ApiCommonService<CalendarItem> {
    private baseUrl = `${environment.url}/${environment.version}/calendar-item`;
    protected resourceUrl = `${environment.url}/${environment.version}/calendar-item`;

    fromJson(json: CalendarItem): CalendarItem {
        return CalendarItem.fromJson(json);
    }

    toJson(model: CalendarItem): CalendarItem {
        return CalendarItem.toJson(model);
    }

    public updateCalendarItem(token: string, model: CalendarItem) {
        return super.update(token, model);
    }

    public getCalendarItems(id: string, token: string) {
        return super.getAll(token, id);
    }
}
