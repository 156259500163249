/**
 * Class with the data for an tag
 */
export class Tag {
    /**
     *@ignore
     */
    constructor(
        public name?: string,
        public color?: string,
        public guideIds?: Array<string>,
        public meetIds?: Array<string>,
        public updatedAt?: Date,
        public uuid?: string,
    ) {}

    /**
     * Method returning an Tag created from json data
     */
    static fromJson(json: Partial<Tag>): Tag {
        return new Tag(
            json.name ?? undefined,
            json.color ?? undefined,
            json.guideIds ?? undefined,
            json.meetIds ?? undefined,
            json.updatedAt ?? undefined,
            json.uuid ?? undefined,
        );
    }

    /**
     * Method returning Tag in a json format
     */
    static toJson(tag: Tag): Tag {
        return {
            name: tag.name ?? undefined,
            color: tag.color ?? undefined,
        };
    }
}
