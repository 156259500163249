import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Newsletter } from '../../models/newsletter/newsletter';
import { Tag } from '../../models/tag/tag';

export class NewsletterForm {
    public newsletterForm: FormGroup;

    constructor() {
        let fb: FormBuilder = new FormBuilder();

        this.newsletterForm = fb.group({
            name: [undefined, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
            status: [undefined, Validators.required],
            category: [undefined],
            tags: [undefined],
            content: [undefined],
            url: [undefined, Validators.required],
            redirectUrl: [undefined],
        });
    }

    get form(): FormGroup {
        return this.newsletterForm;
    }

    markAsDirty(): void {
        Object.keys(this.form.controls).forEach((key) => {
            const control = this.form.get(key);

            if (control instanceof FormGroup) {
                this.markAsDirty();
            } else {
                if (control && !control.valid) {
                    control.markAsDirty();
                }
            }
        });
    }

    resetForm(): void {
        this.newsletterForm.reset();
    }

    fromNewsletter(newsletter: Newsletter): void {
        if (newsletter) {
            this.newsletterForm.patchValue({
                name: newsletter.name || undefined,
                status: newsletter.status || undefined,
                category: newsletter.category?.uuid || undefined,
                tags: newsletter.tags?.map((tag: Tag) => tag.uuid) || undefined,
                content: newsletter.content || undefined,
                url: newsletter.url || undefined,
                redirectUrl: newsletter.redirectUrl || undefined,
            });
        }
    }

    toNewsletter(): Newsletter {
        const formValue = this.newsletterForm.value;
        return new Newsletter(
            formValue.name,
            formValue.status,
            formValue.category,
            formValue.tags,
            formValue.content,
            formValue.url,
            formValue.redirectUrl,
        );
    }
}
