<div class="p-4 flex flex-col justify-between h-full text-center">
    <div>
        <img src="../../../assets/aidants_ressources/logos/les-aidants_logo.png" alt="Logo" class="h-6 mx-auto">
    </div>
    <div class="text-left">
        <ul class="space-y-5 text-color-aidant-dark">
            <li class="ml-[-1rem] p-3 pl-9 rounded-r-2xl cursor-pointer hover:bg-slate-100 hover:text-[#184e77c5]" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/']"><a class="text-lg font-bold">Dashboards</a></li>
            <li class="ml-[-1rem] p-3 pl-9 rounded-r-2xl cursor-pointer hover:bg-slate-100 hover:text-[#184e77c5]" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/ehpads']"><a class="text-lg font-bold">Etablissements</a></li>
            <li class="ml-[-1rem] p-3 pl-9 rounded-r-2xl cursor-pointer hover:bg-slate-100 hover:text-[#184e77c5]" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/meets']"><a class="text-lg font-bold">Evénements</a></li>
            <li class="ml-[-1rem] p-3 pl-9 rounded-r-2xl cursor-pointer hover:bg-slate-100 hover:text-[#184e77c5]" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/guides']"><a class="text-lg font-bold">Guides</a></li>
            <li class="ml-[-1rem] p-3 pl-9 rounded-r-2xl cursor-pointer hover:bg-slate-100 hover:text-[#184e77c5]" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/newsletters']"><a class="text-lg font-bold">Actualités</a></li>
            <li class="ml-[-1rem] p-3 pl-9 rounded-r-2xl cursor-pointer hover:bg-slate-100 hover:text-[#184e77c5]" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/users']"><a class="text-lg font-bold">Utilisateurs</a></li>
            <li class="ml-[-1rem] p-3 pl-9 rounded-r-2xl cursor-pointer hover:bg-slate-100 hover:text-[#184e77c5]" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/dictionnary']"><a class="text-lg font-bold">Dictionnaire</a></li>
            <li class="ml-[-1rem] p-3 pl-9 rounded-r-2xl cursor-pointer hover:bg-slate-100 hover:text-[#184e77c5]" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/threads']"><a class="text-lg font-bold">Chatbot</a></li>
        </ul>
    </div>
    <div>
        <a class="font-semibold text-sm text-color-aidant-dark underline cursor-pointer" (click)="userService.disconnect()">Déconnexion</a>
    </div>
</div>
