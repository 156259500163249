import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserPermission } from '../../models/user-permission/user-permission';

export class UserPermissionForm {
    public userPermissionForm: FormGroup;

    constructor() {
        let fb: FormBuilder = new FormBuilder();

        this.userPermissionForm = fb.group({
            ehpads: [[], []],
        });
    }

    get form(): FormGroup {
        return this.userPermissionForm;
    }

    markAsDirty(): void {
        Object.keys(this.form.controls).forEach((key) => {
            const control = this.form.get(key);

            if (control instanceof FormGroup) {
                this.markAsDirty();
            } else {
                if (control && !control.valid) {
                    control.markAsDirty();
                }
            }
        });
    }

    resetForm(): void {
        this.userPermissionForm.reset();
    }

    fromUserPermission(userPermission: UserPermission): void {
        if (userPermission) {
            this.userPermissionForm.patchValue({
                ehpads: userPermission.ehpads || undefined,
            });
        }
    }

    toUserPermission(): UserPermission {
        const formValue = this.userPermissionForm.value;

        return new UserPermission(formValue.ehpads);
    }
}
