import { Injectable } from '@angular/core';

/**
 *@ignore
 */
@Injectable({
    providedIn: 'root',
})
/**
 * Class to manage the authentication system in Front-end
 */
export class AuthService {
    /**
     *@ignore
     */
    constructor() {}

    /**
     * Method to save the authentication token inside the local storage
     */
    setToken(token: string): void {
        localStorage.setItem('token', token);
    }
    /**
     * Method returning a string of the token inside the local storage
     */
    getToken(): string {
        return localStorage.getItem('token') === null ? '' : localStorage.getItem('token') + '';
    }
    /**
     * Method to remove the token from the local storage
     */
    removeToken(): void {
        localStorage.removeItem('token');
    }
    /**
     * Method returning a boolean to check if the user is authenticated
     */
    isAuthenticated(): boolean {
        return !(localStorage.getItem('token') === '' || localStorage.getItem('token') === null);
    }
}
