<div class="mb-12 shadow">
    <h1 class="text-color-aidant-dark py-6 px-6 mb-6 font-bold text-3xl">{{ isNew ? title[0] : title[1] }}</h1>
    <ul class="pl-6 flex items-center space-x-10 h-full">
        <a
            (click)="setSelectedPage('etablissement')"
            [class.active-link]="selectedPage === 'etablissement'"
            class="p-2 text-neutral-68 text-base font-medium transition-transform transform text-color-aidant-dark hover:text-[#0e3350] hover:scale-105 hover-link cursor-pointer"
        >
            Établissement
        </a>
        <a
            (click)="setSelectedPage('place-accueil')"
            [class.active-link]="selectedPage === 'place-accueil'"
            class="p-2 text-neutral-68 text-base font-medium transition-transform transform text-color-aidant-dark hover:text-[#0e3350] hover:scale-105 hover-link cursor-pointer"
        >
            Places en accueil
        </a>
    </ul>
</div>
<app-ehpad-information-editor *ngIf="this.selectedPage === 'etablissement'" [isLoading]="isLoading" [ehpadForm]="ehpadForm" [cancel]="cancel.bind(this)" [onSubmit]="onSubmit.bind(this)"></app-ehpad-information-editor>
<app-ehpad-places-editor *ngIf="this.selectedPage === 'place-accueil'" [isLoading]="isLoading" [ehpadForm]="ehpadForm" [cancel]="cancel.bind(this)" [onSubmit]="onSubmit.bind(this)"></app-ehpad-places-editor>
<p-toast position="bottom-center" [breakpoints]="{'1920px': {width: '350px'}}"></p-toast>
