import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ThreadForm } from '@lesaidantsbackoffice/core/forms';
import { Thread } from '@lesaidantsbackoffice/core/models';
import { ThreadService } from '@lesaidantsbackoffice/core/services/services';
import { AuthService } from 'src/app/core/auth';

@Component({
  selector: 'app-thread-generation',
  templateUrl: './thread-generation.component.html',
  styleUrls: ['./thread-generation.component.scss']
})
export class ThreadGenerationComponent implements OnInit {

  @Input("thread") thread: Thread | undefined
  @Input('setCreatingToFalse') setCreatingToFalse!: (boolean: boolean) => void


  constructor(private _router: Router, private _threadService: ThreadService, private _authService: AuthService) {}

  public threadForm!: ThreadForm

  ngOnInit(): void {
    this.threadForm = new ThreadForm()

    if (this.thread)
      this.threadForm.form.patchValue({
        previous: this.thread.uuid
      })
  }

  public navigateAgain() {
    if (this.thread)
      this.setCreatingToFalse(false)
    else
      this._router.navigate(['threads'])
  }

  public submitForm() {
    if (this.threadForm.form.invalid)
      return
    let newThread: Thread = this.threadForm.toThread()
    this._threadService.createThread(this._authService.getToken(), newThread).subscribe(r => {
      if (!r || typeof r === "object" && "statusText" in r) {
        return
      }
      if (this.thread) {
        this.thread.threads?.push({
          choice: r.title!,
          threadUUID: r.uuid!
        })
        this.setCreatingToFalse(false)
        return
      }
      this._router.navigate(['threads'])
    })
  }
}
