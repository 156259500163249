import { Component } from '@angular/core';

@Component({
    selector: 'app-guides',
    templateUrl: './guides.component.html',
    styleUrls: ['./guides.component.scss'],
})
export class GuidesComponent {
    constructor() {}
}
