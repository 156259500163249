import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Meet } from '@lesaidantsbackoffice/core/models';
import { MeetService } from '@lesaidantsbackoffice/core/services/services';
import { catchError, finalize, map, of } from 'rxjs';
import { AuthService } from 'src/app/core/auth';
import { APICallReturn } from 'src/app/core/services/APICallReturn';

@Component({
    selector: 'app-meet-list',
    templateUrl: './meet-list.component.html',
    styleUrls: ['./meet-list.component.scss'],
})
export class MeetListComponent implements OnInit {
    meets: Meet[] = [];
    search: string = '';
    isLoading = false;

    constructor(
        private router: Router,
        private authService: AuthService,
        public meetService: MeetService,
    ) {}

    ngOnInit(): void {
        this.isLoading = true;
        this.meetService
            .getMeets(this.authService.getToken())
            .pipe(
                map((meet: APICallReturn | Meet[] | undefined) => {
                    if (Array.isArray(meet) && meet.length > 0 && meet[0] instanceof Meet) {
                        this.meets = meet;
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    delete(id: string): void {
        this.meets.splice(this.meets.findIndex((meet) => meet.uuid === id), 1);
        // still need to update pagination and total count
        this.meetService.deleteMeet(id).subscribe();
    }

    create(): void {
        this.router.navigate(['/meet/new']);
    }

    edit(id: string): void {
        this.router.navigate([`meet/${id}`]);
    }
}
