import { Component, Input } from '@angular/core';
import { Thread } from '@lesaidantsbackoffice/core/models';
import { ThreadService } from '@lesaidantsbackoffice/core/services/services';
import { MenuItem, MenuItemCommandEvent } from 'primeng/api';
import { AuthService } from 'src/app/core/auth';

@Component({
  selector: 'app-breadcrumb-thread',
  templateUrl: './breadcrumb-thread.component.html',
  styleUrls: ['./breadcrumb-thread.component.scss']
})
export class BreadcrumbThreadComponent {

  constructor(private _authService: AuthService, private _threadService: ThreadService) {}
  
  @Input("thread") thread!: Thread
  @Input('menuItem') menuItem!: MenuItem[]
  @Input('fillBreadCrumb') fillBreadCrumb!: (uuid: string, isAdding: boolean, isRoot: boolean) => void
  @Input('setCreatingToTrue') setCreatingToTrue!: (boolean: boolean) => void

  public saveThread() {
    this._threadService.updateThread(this._authService.getToken(), this.thread, this.thread.uuid!).subscribe(r => {
      if (typeof r === "object" && "statusText" in r)
        return
    })
  }

  public deleteThread(item: any) {
    this._threadService.deleteThread(this._authService.getToken(), item.threadUUID).subscribe(r => {
      if (typeof r === "object" && "statusText" in r)
        return
      let threadToDelete: number = this.thread.threads!.indexOf(item)
      
      this.thread.threads?.splice(threadToDelete, 1)
    })
  }
}
