export type ColorType = {
    name: string;
    code: string;
};

export const Colors: ColorType[] = [
    { name: 'Les-Aidants', code: '#184E77' },
    { name: 'Rouge', code: '#FF0000' },
    { name: 'Vert', code: '#00FF00' },
    { name: 'Bleu', code: '#0000FF' },
    { name: 'Noir', code: 'black' },
];
