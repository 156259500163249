import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../../models/user/user';

export class UserForm {
    public userForm: FormGroup;
    public status: string[] = ['Etudiant', 'Salarié', 'Entrepreneur', 'Sans-emploi', 'Retraité', 'Autre'];

    constructor() {
        let fb: FormBuilder = new FormBuilder();

        this.userForm = fb.group({
            firstName: [undefined, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
            lastName: [undefined, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
            email: [undefined, [Validators.required, Validators.email]],
            password: [undefined, [Validators.required]],
            passwordChecker: [undefined, [Validators.required]],
            phoneNumber: [undefined, [Validators.required, Validators.pattern(/^\d{10}$/)]],
            address: [undefined, [Validators.required, Validators.maxLength(100)]],
            city: [undefined, [Validators.required, Validators.maxLength(50)]],
            zipCode: [undefined, [Validators.required, Validators.pattern(/^\d{5}$/)]],
            status: [undefined, Validators.required],
            isValidated: [undefined, Validators.required],
            isProfessional: [false],
        });
    }

    get form(): FormGroup {
        return this.userForm;
    }

    checkFormValidity(): void {
        const invalidFields: String[] = [];

        Object.keys(this.form.controls).forEach((key) => {
            const control = this.form.get(key);
            if (control && control.invalid) {
                invalidFields.push(key);
            }
        });
        console.log(invalidFields);
    }

    markAsDirty(): void {
        Object.keys(this.form.controls).forEach((key) => {
            const control = this.form.get(key);

            if (control instanceof FormGroup) {
                this.markAsDirty();
            } else {
                if (control && !control.valid) {
                    control.markAsDirty();
                }
            }
        });
    }

    resetForm(): void {
        this.userForm.reset();
    }

    fromUser(user: User): void {
        if (user) {
            this.userForm.patchValue({
                firstName: user.firstName || undefined,
                lastName: user.lastName || undefined,
                email: user.email || undefined,
                password: user.password || undefined,
                phoneNumber: user.phoneNumber || undefined,
                address: user.address || undefined,
                city: user.city || undefined,
                zipCode: user.zipCode || undefined,
                status: user.status || undefined,
                isValidated: user.isValidated || undefined,
            });
        }
    }

    toUser(): User {
        const formValue = this.userForm.value;
        return new User(
            formValue.firstName,
            formValue.lastName,
            formValue.email,
            formValue.password,
            formValue.phoneNumber,
            formValue.address,
            formValue.city,
            formValue.zipCode,
            formValue.status,
            formValue.isValidated,
        );
    }
}
