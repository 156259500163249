import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Category } from '../../models/category/category';

export class CategoryForm {
    public categoryForm: FormGroup;

    constructor() {
        let fb: FormBuilder = new FormBuilder();

        this.categoryForm = fb.group({
            name: [undefined, [Validators.required, Validators.maxLength(50)]],
            color: [undefined, [Validators.required]],
        });
    }

    get form(): FormGroup {
        return this.categoryForm;
    }

    markAsDirty(): void {
        Object.keys(this.form.controls).forEach((key) => {
            const control = this.form.get(key);

            if (control instanceof FormGroup) {
                this.markAsDirty();
            } else {
                if (control && !control.valid) {
                    control.markAsDirty();
                }
            }
        });
    }

    resetForm(): void {
        this.categoryForm.reset();
    }

    fromCategory(category: Category): void {
        if (category) {
            this.categoryForm.patchValue({
                name: category.name || undefined,
                color: category.color || undefined,
            });
        }
    }

    toCategory(): Category {
        const formValue = this.categoryForm.value;
        return new Category(formValue.name, formValue.color);
    }
}
