import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { APICallReturn } from '../APICallReturn';
/**
 *@ignore
 */
@Injectable() // Ajout du décorateur Injectable
/**
 * Abstract class for a common api service
 */
export abstract class ApiCommonService<T> {
    /**
     * URL to ping
     */
    protected abstract resourceUrl: string;
    /**
     *@ignore
     */
    constructor(protected _httpClient: HttpClient) {}
    /**
     * Method returning an Ehpad created from json data
     */
    abstract fromJson(json: any): T;
    /**
     * Method returning an Ehpad in a json format
     */
    abstract toJson(model: T): any;
    /**
     * Method returning all elements of a table
     */
    getAll(token: string, params?: any): Observable<APICallReturn | T[]> {
        const header = new HttpHeaders({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        });
        return this._httpClient.get<T[]>(this.resourceUrl, { headers: header, params }).pipe(
            map((json: any[]) => {
                return json.map((item: any) => this.fromJson(item));
            }),
            catchError((error: HttpErrorResponse) => {
                return of({
                    message: error.message,
                    status: error.status,
                    statusText: error.statusText,
                } as APICallReturn);
            }),
        );
    }
    /**
     * Method returning a specific element of a table
     */
    getById(token: string, id: string): Observable<APICallReturn | T> {
        const url = `${this.resourceUrl}/${id}`;
        const header = new HttpHeaders({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        });
        return this._httpClient.get<T>(url, { headers: header }).pipe(
            map((json: any) => {
                return this.fromJson(json);
            }),
            catchError((error: HttpErrorResponse) => {
                return of({
                    message: error.message,
                    status: error.status,
                    statusText: error.statusText,
                } as APICallReturn);
            }),
        );
    }
    /**
     * Method returning a specific element of a table
     */
    get(token: string): Observable<T> {
        const header = new HttpHeaders({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        });

        return this._httpClient.get<T>(this.resourceUrl, { headers: header }).pipe(
            map((json: any) => {
                return this.fromJson(json);
            }),
        );
    }
    /**
     * Method returning an observable of the post query return
     */
    create(model: T, token?: string): Observable<T> {
        const header = new HttpHeaders({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        });
        return this._httpClient
            .post<T>(this.resourceUrl, this.toJson(model), { headers: header })
            .pipe(map((json: any) => this.fromJson(json)));
    }
    /**
     * Method returning an observable of the put query return
     */
    update(token: string, model: T): Observable<APICallReturn | T> {
        const header = new HttpHeaders({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        });

        return this._httpClient.patch<T>(this.resourceUrl, this.toJson(model), { headers: header }).pipe(
            map((json: any) => this.fromJson(json)),
            catchError((error: HttpErrorResponse) => {
                return of({
                    message: error.message,
                    status: error.status,
                    statusText: error.statusText,
                } as APICallReturn);
            }),
        );
    }
    /**
     * Method to send a delete query
     */
    delete(token: string): Observable<APICallReturn | T> {
        const header = new HttpHeaders({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        });

        return this._httpClient.delete<T>(this.resourceUrl, { headers: header }).pipe(
            catchError((error: HttpErrorResponse) => {
                return of({
                    message: error.message,
                    status: error.status,
                    statusText: error.statusText,
                } as APICallReturn);
            }),
        );
    }
}
