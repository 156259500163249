import { Injectable } from '@angular/core';
import { UserPermission } from '../../models/user-permission/user-permission';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { APICallReturn } from '../APICallReturn';
import { UserPermissionApiService } from './user-permission-api-service';
import { AuthService } from '../../auth';

@Injectable({
    providedIn: 'root',
})
export class UserPermissionService {
    private userPermission: UserPermission = new UserPermission();
    private userPermissionSubject = new BehaviorSubject<UserPermission>(new UserPermission());

    constructor(
        private userPermissionApiService: UserPermissionApiService,
        private authService: AuthService,
    ) {
        const storedUserPermission = sessionStorage.getItem('userPermission');

        if (storedUserPermission) {
            this.userPermission = UserPermission.fromJson(JSON.parse(storedUserPermission));
            this.userPermissionSubject.next(this.userPermission);
        }
    }

    public set(userPermission: Partial<UserPermission>): void {
        this.userPermission = UserPermission.fromJson(userPermission);
        this.userPermissionSubject.next(this.userPermission);
        sessionStorage.setItem('userPermission', JSON.stringify(this.userPermission));
    }

    public reset(): void {
        this.userPermission = new UserPermission();
        this.userPermissionSubject.next(this.userPermission);
    }

    public get(): UserPermission {
        return this.userPermission;
    }

    public getObservable(): Observable<UserPermission> {
        return this.userPermissionSubject.asObservable();
    }

    /* CALL APIS */

    public updateUserPermissions(
        userPermission: Partial<UserPermission>,
        id: string,
    ): Observable<APICallReturn | UserPermission | undefined> {
        if (userPermission === undefined) {
            return of(undefined);
        }
        return this.userPermissionApiService
            .updateUserPermissions(this.authService.getToken(), userPermission, id)
            .pipe(
                map((data: APICallReturn | UserPermission | undefined) => {
                    if (data instanceof UserPermission) {
                        this.set(data);
                    }
                    return data;
                }),
            );
    }

    public getUserPermissions(id: string): Observable<APICallReturn | UserPermission | undefined> {
        return this.userPermissionApiService.getUserPermissions(this.authService.getToken(), id);
    }
}
