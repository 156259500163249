import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { APICallReturn } from '../APICallReturn';
import { AuthService } from '../../auth';
import { GuideApiService } from './guide-api-service';
import { Guide } from '../../models/guide/guide';

@Injectable({
    providedIn: 'root',
})
export class GuideService {
    status: string[] = ['En rédaction', 'En validation', 'Validé'];

    constructor(
        private guideApiService: GuideApiService,
        private authService: AuthService,
    ) {}

    /* CALL APIS */

    public createGuide(token: string, guide: Guide): Observable<APICallReturn | Guide | undefined> {
        if (guide === undefined) {
            return of(undefined);
        }
        return this.guideApiService.createGuide(token, guide).pipe();
    }

    public updateGuide(
        token: string,
        guide: Partial<Guide>,
        id: string,
    ): Observable<APICallReturn | Guide | undefined> {
        if (guide === undefined) {
            return of(undefined);
        }
        return this.guideApiService.updateGuide(token, guide, id).pipe(
            map((data: APICallReturn | Guide | undefined) => {
                return data;
            }),
        );
    }

    public getGuide(id: string): Observable<APICallReturn | Guide | undefined> {
        return this.guideApiService.getGuide(this.authService.getToken(), id);
    }

    public getGuides(): Observable<APICallReturn | Guide[] | undefined> {
        return this.guideApiService.getGuides(this.authService.getToken());
    }

    public deleteGuide(id: string): Observable<APICallReturn | Guide | undefined> {
        return this.guideApiService.deleteGuide(this.authService.getToken(), id);
    }
}
