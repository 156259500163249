import { Component } from '@angular/core';

@Component({
  selector: 'app-threads',
  templateUrl: './threads.component.html',
  styleUrls: []
})
export class ThreadsComponent {

}
