import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { APICallReturn } from '../APICallReturn';
import { AuthService } from '../../auth';
import { TagApiService } from './tag-api-service';
import { Tag } from '../../models/tag/tag';

@Injectable({
    providedIn: 'root',
})
export class TagService {
    private tags: Tag[] = [];

    constructor(
        private tagApiService: TagApiService,
        private authService: AuthService,
    ) {}

    public get(): Tag[] {
        return this.tags;
    }

    /* CALL APIS */

    public createTag(tag: Tag): Observable<APICallReturn | Tag | undefined> {
        if (tag === undefined) {
            return of(undefined);
        }
        return this.tagApiService.createTag(tag).pipe();
    }

    public updateTag(id: string, tag: Partial<Tag>): Observable<APICallReturn | Tag | undefined> {
        if (tag === undefined) {
            return of(undefined);
        }
        return this.tagApiService.updateTag(this.authService.getToken(), id, tag).pipe(
            map((data: APICallReturn | Tag | undefined) => {
                if (data instanceof Tag) {
                    const index = this.tags.findIndex((e) => e.uuid === data.uuid);
                    if (index !== -1) {
                        this.tags[index] = data;
                    }
                }
                return data;
            }),
        );
    }

    public getTags(): Observable<APICallReturn | Tag[] | undefined> {
        return this.tagApiService.getTags(this.authService.getToken());
    }

    public deleteTag(id: string): Observable<APICallReturn | Tag | undefined> {
        return this.tagApiService.deleteTag(this.authService.getToken(), id);
    }
}
