import { ApiCommonService } from '../common/api-common.service';
import { User } from '../../models/user/user';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '@lesaidants/env';
import { APICallReturn } from '../APICallReturn';

/**
 *@ignore
 */
@Injectable({
    providedIn: 'root',
})
/**
 * Class used to fill and send a User object
 */
export class UserApiService extends ApiCommonService<User> {
    /**
     * URL to ping
     */
    private baseUrl = `${environment.url}/${environment.version}/user`;
    protected resourceUrl = `${environment.url}/${environment.version}/user`;
    /**
     * Method returning a User created from json data
     */
    fromJson(json: any): User {
        return User.fromJson(json);
    }
    /**
     * Method returning any in a json format
     */
    toJson(model: User): any {
        return User.toJson(model);
    }
    /**
     * Method returning Observable User get by id
     */
    getUser(token: string, id?: string): Observable<APICallReturn | User | undefined> {
        this.resourceUrl = `${this.baseUrl}/info`;
        if (!!id) {
            return super.getById(token, id);
        }
        return super.get(token);
    }
    /**
     * Method returning Observable Users list
     */
    getUsers(token: string): Observable<APICallReturn | User[] | undefined> {
        this.resourceUrl = `${this.baseUrl}/list`;
        return super.getAll(token);
    }
    /**
     * Method returning Observable User update by auth token
     */
    updateUser(token: string, user: User, id: string): Observable<APICallReturn | User | undefined> {
        this.resourceUrl = `${this.baseUrl}/update/${id}`;
        return super.update(token, user);
    }
    /**
     * Method returning Observable User create by auth token
     */
    createUser(user: User, token?: string): Observable<APICallReturn | User | undefined> {
        this.resourceUrl = `${this.baseUrl}/register`;
        return super.create(user, token);
    }
    /**
     * Method returning Observable User create by auth token
     */
    loginUser(user: User): Observable<APICallReturn | User | undefined> {
        this.resourceUrl = `${environment.url}/${environment.version}/auth/login`;
        return super.create(user);
    }
    /**
     * Method to delete User by id
     */
    deleteUser(token: string, id: string): Observable<APICallReturn | User | undefined> {
        this.resourceUrl = `${this.baseUrl}/delete/${id}`;
        return super.delete(token);
    }
    /**
     * Method to disconnect all Users
     */
    refreshTokenUser(token: string, id: string): Observable<APICallReturn | {} | undefined> {
        this.resourceUrl = `${this.baseUrl}/token/refresh/${id}`;
        return super.update(token, {});
    }
}
