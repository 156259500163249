import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Guide } from '@lesaidantsbackoffice/core/models';
import { CategoryService, GuideService, TagService } from '@lesaidantsbackoffice/core/services/services';
import { MessageService } from 'primeng/api';
import { catchError, finalize, map, of } from 'rxjs';
import { APICallReturn } from 'src/app/core/services/APICallReturn';

@Component({
    selector: 'app-guide-list',
    templateUrl: './guide-list.component.html',
    styleUrls: ['./guide-list.component.scss'],
})
export class GuideListComponent implements OnInit {
    guides: Guide[] = [];
    search: string = '';
    isLoading = false;

    constructor(
        private router: Router,
        public guideService: GuideService,
        public categoryService: CategoryService,
        public tagService: TagService,
        private messageService: MessageService,
    ) {}

    ngOnInit(): void {
        this.isLoading = true;
        this.fetchGuides();
    }

    create(): void {
        this.router.navigate(['/guide/new']);
    }

    edit(id: string): void {
        this.router.navigate([`guide/${id}`]);
    }

    delete(id: string): void {
        this.guideService
            .deleteGuide(id)
            .pipe(
                map(() => {
                    this.messageService.add({
                        severity: 'success',
                        detail: 'Établissement supprimé',
                    });
                    this.fetchGuides();
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    private fetchGuides(): void {
        this.guideService
            .getGuides()
            .pipe(
                map((guides: APICallReturn | Guide[] | undefined) => {
                    if (Array.isArray(guides) && guides.length > 0 && guides[0] instanceof Guide) {
                        this.guides = guides;
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }
}
