import { Meet } from "../meet/meet";

/**
 * Class with the data for an calendarItem
 */
export class CalendarItem {

    /**
     * @ignore
    */
    constructor(
        public id?: number,
        public uuid?: string, 
        public color?: string,
        public icon?: string,
        public meet?: Meet,
        public reminder?: string,
        public acceptated?: boolean,
    ) {}

    /**
     * Method returning an calendarItem created from json data
    */
    static fromJson(json: Partial<CalendarItem>): CalendarItem {
        return new CalendarItem(
            json.id ?? undefined,
            json.uuid ?? undefined,
            json.color ?? undefined,
            json.icon ?? undefined,
            json.meet ?? undefined,
            json.reminder ?? undefined,
            json.acceptated ?? undefined,
        );
    }

    /**
     * Method returning calendarItem in a json format
    */
    static toJson(calendarItem: CalendarItem): CalendarItem {
        return {
            id: calendarItem.id ?? undefined,
            uuid: calendarItem.uuid ?? undefined,
            color: calendarItem.color ?? undefined,
            icon: calendarItem.icon ?? undefined,
            meet: calendarItem.meet ?? undefined,
            reminder: calendarItem.reminder ?? undefined,
            acceptated: calendarItem.acceptated ?? undefined,
        };
    }
}