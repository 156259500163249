import { Component, Input, OnInit } from '@angular/core';
import { UserProForm } from '@lesaidantsbackoffice/core/forms';
import { UserPro } from '@lesaidantsbackoffice/core/models';
import { UserProService } from '@lesaidantsbackoffice/core/services/services';
import { MessageService } from 'primeng/api';
import { catchError, finalize, map, of } from 'rxjs';
import { APICallReturn } from 'src/app/core/services/APICallReturn';

@Component({
    selector: 'app-user-professional-editor',
    templateUrl: './user-professional-editor.component.html',
    styleUrls: ['./user-professional-editor.component.scss'],
    providers: [MessageService],
})
export class UserProfessionalEditorComponent implements OnInit {
    @Input('id') id!: string;
    userProForm: UserProForm = new UserProForm();
    userPro: UserPro = new UserPro();
    isLoading: boolean = false;

    constructor(
        private messageService: MessageService,
        private userProService: UserProService,
    ) {}

    ngOnInit(): void {
        this.isLoading = true;
        this.userProService
            .getUserPro(this.id)
            .pipe(
                map((userPro: APICallReturn | UserPro | undefined) => {
                    if (userPro instanceof UserPro) {
                        this.userPro = userPro;
                        this.userProForm.fromUserPro(userPro);
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    edit() {
        this.isLoading = true;
        this.userProService
            .updateUserPro(this.userProForm.toUserPro(), this.userPro.id?.toString() || '')
            .pipe(
                map((userPro: APICallReturn | UserPro | undefined) => {
                    if (userPro instanceof UserPro) {
                        this.userPro = userPro;
                        this.userProForm.fromUserPro(userPro);
                        this.messageService.add({
                            severity: 'success',
                            detail: 'Utilisateur mis à jour',
                        });
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.messageService.add({
                        severity: 'error',
                        detail: "Erreur lors de la mise à jour de l'utilisateur",
                    });
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    delete() {
        this.isLoading = true;
        this.userProService
            .deleteUserPro(this.userPro.id?.toString() || '')
            .pipe(
                map((userPro: APICallReturn | UserPro | undefined) => {
                    if (userPro instanceof UserPro) {
                        this.userPro = new UserPro();
                        this.userProForm.fromUserPro(userPro);
                        this.messageService.add({
                            severity: 'success',
                            detail: 'Compte professionnel supprimé',
                        });
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.messageService.add({
                        severity: 'error',
                        detail: 'Erreur lors de la suppression du compte professionnel',
                    });
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    onSubmit(): void {
        if (this.userProForm?.form.valid) {
            this.edit();
        } else {
            this.userProForm?.markAsDirty();
        }
    }

    onDelete(): void {
        this.delete();
    }
}
