<div *ngIf="this.modal && this.selectItem !== undefined" class="absolute justify-center flex items-center w-[400px] h-[400px]  z-40 " [style]="{'left': (this.XClick! - 25)  + 'px', 'top': (this.YClick! - 25) + 'px'}">
    <div class="w-[350px] h-[350px] border-2 shadow-xl border-[#DEDEDE] shadow-[#131313] absolute  bg-white">
        <div class="w-full h-[300px] flex flex-col">
            <div class="h-[50px] w-full flex justify-end items-center p-4 gap-4">
                <button  (click)="this.closeModal()"><i class="pi pi-times"></i></button>
            </div>
            <div class="flex-1 w-full ml-5">
                <form *ngIf="this.form" [formGroup]="this.form.calendarForm">
                    <h1 class="text-lg mb-4">{{this.selectItem.meet?.name}}</h1>
                    <p class="text-sm mb-4">{{getDate()}}</p>
                    <p class="flex items-center mb-4">color : <input class="ml-4" type="color" formControlName="color"></p>
                    <p class="flex items-center mb-4"><i class="pi pi-bell mr-3"></i>rappel :
                        <span *ngIf="!this.editable">&nbsp;{{this.selectItem.reminder}}</span>
                        <p-dropdown formControlName="reminder" styleClass="ml-4 h-[50px]" *ngIf="this.form && this.editable" [options]="this.form.reminders">30 minutes avant</p-dropdown>
                    </p>
                    <p-button styleClass="h-[30px]" *ngIf="this.editable" (click)="this.updateEvent()">Submit</p-button>
                </form>
            </div>
        </div>
    </div>
</div>

<full-calendar #calendar [options]="calendarOptions" class="w-full h-full p-4" (click)="this.getWhere($event)"></full-calendar>