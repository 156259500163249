<div class="h-full flex flex-col">
    <div class="select-none">
        <div class="pb-[42px] shadow mb-12">
            <h1 class="dark-color py-6 px-6 mb-6 font-bold text-3xl">{{this.thread ? "Ajout de réponse à " + this.thread.title : "Création d'un nouveau thread" }}</h1>
        </div>
    </div>
    <div class="flex w-full pl-6 underline text-xl mb-12">
        <p-button (click)="this.navigateAgain()">Retour en arrière</p-button>
    </div>
    <div class="flex flex-col items-center h-full pt-12 w-full">
        <div class="w-1/4">
            <form [formGroup]="this.threadForm.form">
                <span class="p-float-label mb-12">
                    <input pInputText class="w-full" formControlName="title" type="text" />
                    <label for="inputtext">Titre</label>
                </span>
                <span class="p-float-label mb-12">
                    <input pInputText class="w-full" formControlName="message" type="text" />
                    <label for="inputtext">Message</label>
                </span>
                <div class="flex justify-end w-full">
                    <p-button (click)="this.submitForm()">Créer le thread</p-button>
                </div>
            </form>
        </div>
    </div>
</div>