<div class="w-full px-8 relative text-color-aidant-dark">
    <div class="flex flex-nowrap">
        <div *ngIf="this.userForm" class="flex flex-col justify-center w-1/2">
            <div class="w-9/12">
                <form [formGroup]="this.userForm.form">
                    <span class="p-float-label flex mb-10 items-center">
                        <input pInputText id="firstName" class="w-full" type="text" formControlName="firstName" />
                        <label for="firstName">Prénom</label>
                    </span>
                    <span class="p-float-label flex mb-10 items-center">
                        <input pInputText id="lastName" class="w-full" type="text" formControlName="lastName" />
                        <label for="lastName">Nom</label>
                    </span>
                    <span class="p-float-label flex mb-10 items-center">
                        <input pInputText id="email" class="w-full" type="email" formControlName="email" />
                        <label for="email">Email</label>
                    </span>
                    <span class="p-float-label flex mb-10 items-center">
                        <input pInputText id="phoneNumber" class="w-full" type="text" formControlName="phoneNumber" />
                        <label for="phoneNumber">Téléphone</label>
                    </span>
                    <span class="p-float-label flex mb-10 items-center">
                        <input pInputText id="address" class="w-full" type="text" formControlName="address" />
                        <label for="address">Nom et numéro de voie</label>
                    </span>
                    <span class="p-float-label flex mb-10 items-center">
                        <input pInputText id="city" class="w-full" type="text" formControlName="city" />
                        <label for="city">Ville</label>
                    </span>
                    <span class="p-float-label flex mb-10 items-center">
                        <input pInputText id="zipCode" class="w-full" type="number" formControlName="zipCode" />
                        <label for="zipCode">Code postal</label>
                    </span>
                    <span class="p-float-label w-full mb-10">
                        <p-dropdown styleClass="w-full text-left" inputId="status" [options]="this.userForm.status" formControlName="status"></p-dropdown>
                        <label for="status">Catégorie socioprofessionnelle</label>
                    </span>
                    <div class="mb-2 flex justify-center items-center space-x-3">
                        <button pButton label="Valider" class="p-button-raised w-36" (click)="onSubmit()"></button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<p-toast></p-toast>