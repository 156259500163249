import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EhpadForm } from '@lesaidantsbackoffice/core/forms';
import { Month, Monthes } from '@lesaidantsbackoffice/core/objects';

@Component({
    selector: 'app-ehpad-places-editor',
    templateUrl: './ehpad-places-editor.component.html',
    styleUrls: ['./ehpad-places-editor.component.scss'],
})
export class EhpadPlacesEditorComponent implements OnInit {
    @Input() ehpadForm!: EhpadForm;
    @Input() isLoading = false;
    @Input() cancel!: () => void;
    @Input() onSubmit!: () => void;
    private actualMonth: Month = Monthes[new Date().getMonth()];
    monthes: Month[] = Monthes.slice(this.actualMonth.digit - 1, this.actualMonth.digit + 3);

    ngOnInit(): void {
        console.log('HERE', this.ehpadForm.ehpadForm.get('availabilityPerMonth')?.value);
        const availabilities = this.ehpadForm.ehpadForm.get('availabilityPerMonth')?.value || {};
        const availabilityPerMonthGroup = this.ehpadForm.ehpadForm.get('availabilityPerMonth') as FormGroup;

        this.monthes.forEach((month) => {
            const monthDigit = month.digit.toString();

            if (!availabilityPerMonthGroup.get(monthDigit)) {
                availabilityPerMonthGroup.addControl(monthDigit, new FormControl(0));
            } else {
                const control = availabilityPerMonthGroup.get(monthDigit) as FormControl;
                control.setValue(availabilities[monthDigit] || 0);
            }
        });
    }
}
